.com { color: #93a1a1; }
.lit { color: #195f91; }
.pun, .opn, .clo { color: #93a1a1; }
.fun { color: #dc322f; }
.str, .atv { color: #D14; }
.kwd, .prettyprint .tag { color: #1e347b; padding:0;border:0;}
.prettyprint .tag:hover, .prettyprint .tag:focus, .prettyprint .tag:active{ color: #1e347b;background:none;}
.typ, .atn, .dec, .var { color: teal; }
.pln { color: #48484c; }

.prettyprint {
  padding: 25px;
  background-color:#fff;
  border: 1px solid #fff;
  border-radius:2px;
  text-align:left;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin:0; /* IE indents via margin-left */
}
ol.linenums li {
  padding-left:0;
  color: #616161;
}
