/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 21, 2014 */



@font-face {
    font-family: 'open_sans';
    src: url("../fonts/opensans/opensans-light-webfont.eot");
    src: url("../fonts/opensans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/opensans/opensans-light-webfont.woff2") format("woff2"),
         url("../fonts/opensans/opensans-light-webfont.woff") format("woff"),
         url("../fonts/opensans/opensans-light-webfont.ttf") format("truetype"),
         url("../fonts/opensans/opensans-light-webfont.svg#open_sanslight") format("svg");
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'open_sans';
    src: url("../fonts/opensans/opensans-regular-webfont.eot");
    src: url("../fonts/opensans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/opensans/opensans-regular-webfont.woff2") format("woff2"),
         url("../fonts/opensans/opensans-regular-webfont.woff") format("woff"),
         url("../fonts/opensans/opensans-regular-webfont.ttf") format("truetype"),
         url("../fonts/opensans/opensans-regular-webfont.svg#open_sansregular") format("svg");
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'open_sans';
    src: url("../fonts/opensans/opensans-semibold-webfont.eot");
    src: url("../fonts/opensans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/opensans/opensans-semibold-webfont.woff2") format("woff2"),
         url("../fonts/opensans/opensans-semibold-webfont.woff") format("woff"),
         url("../fonts/opensans/opensans-semibold-webfont.ttf") format("truetype"),
         url("../fonts/opensans/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
    font-weight: 600;
    font-style: normal;

}



@font-face {
    font-family: 'open_sans';
    src: url("../fonts/opensans/opensans-bold-webfont.eot");
    src: url("../fonts/opensans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"),
         url("../fonts/opensans/opensans-bold-webfont.woff2") format("woff2"),
         url("../fonts/opensans/opensans-bold-webfont.woff") format("woff"),
         url("../fonts/opensans/opensans-bold-webfont.ttf") format("truetype"),
         url("../fonts/opensans/opensans-bold-webfont.svg#open_sansbold") format("svg");
    font-weight: 700;
    font-style: normal;

}
