/* ==============================================================

Template name : Bootsnav - Multi Purpose Header
Categorie : Bootstrap Menu in CSS
Author : adamnurdin01
Version : v1.0.0
Created : 02 Juni  2016
Last update : 02 Juni  2016

============================================================== */

/* MEGAMENU STYLE
=================================*/
nav.bootsnav .dropdown.megamenu-fw {
    position: static;
}

nav.bootsnav .container {
    position: relative;
}

nav.bootsnav .megamenu-fw .dropdown-menu {
    left: auto;
}

nav.bootsnav .megamenu-content {
    padding: 15px;
    width: 100% !important;
}

nav.bootsnav .megamenu-content .title{
    margin-top: 0;
}

nav.bootsnav .dropdown.megamenu-fw .dropdown-menu {
    left: 0;
    right: 0;
}

/* Navbar
=================================*/
nav.navbar.bootsnav{
    margin-bottom: 0;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    border: none;
    z-index: 9;
}

nav.navbar.navbar-default{
    background-color: #fff;
    border-bottom: solid 1px #e0e0e0;
}

nav.navbar.navbar-default.navlinner{
	border:none;
}

nav.navbar.navbar-default.white:before{
	position:absolute;
	width:857px;
	height:29px;
	background:url(img/shadow.png) no-repeat 0 0;
	content:"";
	bottom:-30px;
	left:50%;
	margin-left:-428.5px;
}

nav.navbar.navbar-default.navbar-fixed.white:before{
	background:none;
}

nav.navbar.bootsnav ul.nav > li > a{
    background-color: transparent !important;
	border-top:3px solid transparent;
}

nav.navbar.navbar-default.navlinner.bootsnav ul.nav > li > a{
	border:none;
	text-transform:uppercase;
}

nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover,
nav.navbar.bootsnav ul.nav li.megamenu-fw > a:focus,
nav.navbar.bootsnav ul.nav li.active > a:hover,
nav.navbar.bootsnav ul.nav li.active > a:focus,
nav.navbar.bootsnav ul.nav li.active > a{
    background-color: transparent;
}

nav.navbar.bootsnav .navbar-toggle{
    border: none;
    padding: 0;
    font-size: 18px;
    position: relative;
    top: 5px;
    background: transparent !important;
}

nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu{
    top: 0;
    left: 100%;
}

nav.navbar.bootsnav ul.nav ul.dropdown-menu > li > a{
    white-space:normal;
}


ul.menu-col{
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.menu-col li a{
    color: #6f6f6f;
}

ul.menu-col li a:hover,
ul.menu-col li a:focus{
    text-decoration: none;
}

/* Navbar Full
=================================*/
nav.bootsnav.navbar-full{
    padding-bottom: 20px;
    padding-top: 20px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

nav.bootsnav.navbar-full-clear{
	background:none !important;
}

nav.bootsnav.navbar-full .navbar-toggle{
    color: #333;
}

nav.navbar.navbar-full.bootsnav.no-background{
	border:none;
}

nav.bootsnav.navbar-full .navbar-header{
    display: block;
    width: 100%;
}

nav.bootsnav.navbar-full .navbar-toggle{
    display: inline-block;
    margin-right: 0;
    position: relative;
    top: 0;
    font-size: 30px;
    -webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

nav.bootsnav.navbar-full .navbar-toggle:hover,
nav.bootsnav.navbar-full .navbar-toggle:focus,
nav.bootsnav.navbar-full .navbar-toggle:active{
	background:none;
}

nav.bootsnav.navbar-full .navbar-collapse{
    position: fixed;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    padding: 0;
    display: none !important;
    z-index: 9;
}

nav.bootsnav.navbar-full .navbar-collapse.in{
    display: block !important;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full{
    overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .wrap-full-menu{
    display: table-cell;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.98);
    overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-webkit-scrollbar {
    width: 0;
}
/*
nav.bootsnav.navbar-full .navbar-collapse .nav-full::-moz-scrollbar {
    width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-ms-scrollbar {
    width: 0;
}

nav.bootsnav.navbar-full .navbar-collapse .nav-full::-o-scrollbar {
    width: 0;
}
*/

nav.bootsnav.navbar-full .navbar-collapse ul.nav{
    display: block;
    width: 100%;
    overflow: auto;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav a:active,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a:hover,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a:focus,
nav.bootsnav.navbar-full .navbar-collapse ul.nav a{
    background-color: transparent;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li{
    float: none;
    display: block;
    text-align: center;
}

nav.bootsnav.navbar-full .navbar-collapse ul.nav > li > a{
    display: table;
    margin: auto;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 24px;
    padding: 20px 15px;
}

li.close-full-menu > a{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

li.close-full-menu{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

/* Atribute Navigation
=================================*/
.attr-nav{
    float: right;
    display: inline-block;
    margin-left: 15px;
    margin-right: -15px;
}

.attr-nav > ul{
    padding: 0;
    margin: 0 0 -7px 0;
    list-style: none;
    display: inline-block;
}

.attr-nav > ul > li{
    float: left;
    display: block;
}

.attr-nav > ul > li > a{
    color: #6f6f6f;
    display: block;
    padding: 28px 15px;
    position: relative;
    z-index: 2;
}

.attr-nav > ul > li > a span.badge{
    position: absolute;
    text-align: center;
    top: 50%;
    margin-top: -15px;
    right: 5px;
    font-size: 10px;
    padding-top: 3px;
    z-index: -1;
}

.attr-nav > ul > li.dropdown ul.dropdown-menu{
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
    border-left: solid 1px transparent !important;
    border-right: solid 1px transparent !important;
    border-bottom: solid 1px transparent !important;
}

ul.cart-list{
    padding: 0 !important;
    width: 250px !important;
}

ul.cart-list > li{
    position: relative;
    border-bottom: solid 1px #efefef;
    padding: 15px 15px 23px 15px !important;
}

ul.cart-list > li > a.photo{
    padding: 0 !important;
    margin-right: 15px;
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    left: 15px;
    top: 15px;
}

ul.cart-list > li img{
    width: 50px;
    height: 50px;
    border: solid 1px #efefef;
}

ul.cart-list > li > h6{
    margin: 0;
}

ul.cart-list > li > h6 > a.photo{
    padding: 0 !important;
    display: block;
}

ul.cart-list > li > p{
    margin-bottom: 0;
}

ul.cart-list > li.total{
    background-color: #f5f5f5;
    padding-bottom: 15px !important;
}

ul.cart-list > li.total > .btn{
    display: inline-block;
	font-weight:700;
	border:none;
	color:#fff !important;
}

ul.cart-list > li.total > .btn:hover{
	background-color:#fd0000 !important;
}

ul.cart-list > li.total > span{
    padding-top: 8px;
}

/* Top Search
=================================*/
.top-search{
    background-color: #333;
    padding: 10px 0;
    display: none;
}

.top-search input.form-control{
    background-color: transparent;
    border: none;
    -moz-box-shadow: 0px 0px 0px;
    -webkit-box-shadow: 0px 0px 0px;
    -o-box-shadow: 0px 0px 0px;
    box-shadow: 0px 0px 0px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
}

.top-search .input-group-addon{
    background-color: transparent;
    border: none;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
}

.top-search .input-group-addon.close-search{
    cursor: pointer;
}

/* Side Menu
=================================*/
body{
    -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

body.on-side{
    margin-left: -280px;
}

.side{
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: -280px;
    width: 280px;
    padding: 25px 30px;
    height: 100%;
    display: block;
    background-color: #fff;
	border-left:1px solid #eeeeee;
    -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
    z-index: 9;
}

.side.on{
    right: 0;
}

.side .close-side{
    float: right;
    color: #333;
    position: relative;
    z-index: 2;
    font-size: 16px;
}

.side .widget{
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
	color:#787878;
}

.side .widget .title{
    color: #333;
    margin-bottom: 15px;
}

.side .widget ul.link{
    padding: 0;
    margin: 0;
    list-style: none;
}

.side .widget ul.link li a{
    color: #9f9f9f;
    letter-spacing: 1px;
}

.side .widget ul.link li a:focus,
.side .widget ul.link li a:hover{
    color: #fff;
    text-decoration: none;
}

.navbar-widget{
	margin:0;
	padding:40px 0;
}

.navbar-widget li{
	list-style:none;
	margin:0;
	padding:0;
}


.navbar-widget li a{
	color:#333;
	font-size:32px;
	line-height:32px;
	font-weight:700;
	padding:15px 0;
	display:block;
}

.navbar-widget li.dropdown > a.dropdown-toggle:before{
	font-family: 'FontAwesome';
	float: left;
	content: "\f067";
	margin-top: 0;
	font-size:24px;
	line-height:38px;
	margin-right:5px;
}

/* Share
=================================*/
nav.navbar.bootsnav .share{
    padding: 0 30px;
    margin:0;
}

nav.navbar.bootsnav .share ul{
    float: right;
    padding: 0;
    margin: 30px 0 -10px 0;
    list-style: none;
}

nav.navbar.bootsnav .share ul > li{
    float: right;
    display: block;
    margin-left: 5px;
}

nav.navbar.bootsnav .share ul > li > a{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 35px;
    height: 35px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: #eeeeee;
    color: #222;
}

/* Transparent
=================================*/
nav.navbar.bootsnav.navbar-fixed{
    position: fixed;
    display: block;
    width: 100%;
}

nav.navbar.bootsnav.no-background{
    -webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

/* Navbar Sticky
=================================*/
.wrap-sticky{
    position: relative;
}

.wrap-sticky nav.navbar.bootsnav{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.wrap-sticky nav.navbar.bootsnav.sticked,
nav.navbar.bootsnav.fixsticky{
    position: fixed;
    -webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
	box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
}

.wrap-sticky nav.navbar.bootsnav.sticked.white:before{
	background:none;
}

nav.navbar.navbar-default.navbar-fixed.fixsticky{
	-webkit-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
	box-shadow: 0px -1px 10px 0px rgba(0,0,0,0.2);
}

body.on-side .wrap-sticky nav.navbar.bootsnav.sticked{
    left: -280px;

}

nav.navbar.bootsnav.navbar-filter.sticked{
	height:55px;
}

/* Navbar Responsive
=================================*/
@media (min-width: 1024px) and (max-width:1400px) {
    body.wrap-nav-sidebar .wrapper .container{
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1024px) {
    /* General Navbar
    =================================*/
    nav.navbar.bootsnav ul.nav .dropdown-menu .dropdown-menu{
        margin-top: -2px;
    }

    nav.navbar.bootsnav ul.nav.navbar-right .dropdown-menu .dropdown-menu{
        left: -200px;
    }
    
    nav.navbar.bootsnav ul.nav > li > a{
        padding: 30px 15px;
    }
    
    nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after{
        font-family: 'FontAwesome';
        content: "\f0d7";
        margin-left: 5px;
        margin-top: 2px;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu{
        -moz-box-shadow: 0px 0px 0px;
        -webkit-box-shadow: 0px 0px 0px;
        -o-box-shadow: 0px 0px 0px;
        box-shadow: 0px 0px 0px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        -o-border-radius: 0px;
        border-radius: 0px;
        padding: 0;
        width: 200px;
        background: #fff;
        border: solid 1px #e0e0e0;
        border-top: solid 5px;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover,
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover{
        background-color: transparent;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a{
        padding: 10px 15px;
        border-bottom: solid 1px #eee;
        color: #6f6f6f;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li:last-child > a{
        border-bottom: none;
    }
    
    nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a{
        text-align: right;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:before{
        font-family: 'FontAwesome';
        float: right;
        content: "\f105";
        margin-top: 0;
    }
    
    nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:before{
        font-family: 'FontAwesome';
        float: left;
        content: "\f104";
        margin-top: 0;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu ul.dropdown-menu{
        top: -3px;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content{
        padding: 0 15px !important;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content > li{
       padding: 25px 0 20px;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed{
        padding: 0;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content.tabbed > li{
        padding: 0;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu{
        padding: 0 30px;
        margin: 0 -0.5px;
        border-left: solid 1px #f0f0f0;
        border-right: solid 1px #f0f0f0;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:first-child{
        border-left: none;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .col-menu:last-child{
        border-right: none;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content{
        display: none;
    }
    
    nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a{
        text-align: left;
        padding: 5px 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        border-bottom: none;
        color: #6f6f6f;
    }
    
    nav.navbar.bootsnav.on ul.dropdown-menu.megamenu-content .content{
        display: block !important;
        height: auto !important;
    }
    
    /* Navbar Transparent
    =================================*/  
    nav.navbar.bootsnav.no-background{
        background-color: transparent;
        border-bottom:1px solid rgba(255, 255, 255, 0.2);
    }
	
    nav.navbar.bootsnav.navbar-transparent .attr-nav{
        padding-left: 15px;
        margin-left: 0;
    }
    
    nav.navbar.bootsnav.navbar-transparent.white{
		background-color: rgba(255, 255, 255, 0.8);
		border-bottom: solid 1px #e0e0e0;
    }
    
    .navbar.navbar-inverse.navbar-centered.navbar-fixed.dark.bootsnav .top-search{
        padding: 20px 0;
        border-bottom: solid 1px #2f2f2f;
    }
    
    .navbar.navbar-inverse.navbar-centered.navbar-fixed.dark.bootsnav > .container-fluid,
    .navbar.navbar-inverse.navbar-centered.navbar-fixed.dark.bootsnav > .container{
        background: #333;
        height: 80px;
    }
    
    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
    nav.navbar.bootsnav.navbar-transparent.dark{
        background-color: rgba(0,0,0,0.3);
        border-bottom: solid 1px #555;
    }
    
    /*nav.navbar.bootsnav.navbar-transparent.white .attr-nav{

    }*/
    
    nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav{
        border-left: solid 1px #555;
    }
    
    nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a,
    nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a{
        color: #333;
    }
	
    nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a,
    nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a{
        color: #eee;
    }
	
	nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a,
    nav.navbar.bootsnav.no-background.white ul.nav > li > a,
    nav.navbar.bootsnav.no-background.dark .attr-nav > ul > li > a,
    nav.navbar.bootsnav.no-background.dark ul.nav > li > a{
        color: #fff;
    }
    
    nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-scrolled,
    nav.navbar.bootsnav.navbar-fixed.no-background .logo-scrolled{
        display: none;
    }

    nav.navbar.bootsnav.navbar-fixed.navbar-transparent .logo-display,
    nav.navbar.bootsnav.navbar-fixed.no-background .logo-display{
        display: block;
    }

    nav.navbar.bootsnav.navbar-fixed .logo-display{
        display: none;
    }

    nav.navbar.bootsnav.navbar-fixed .logo-scrolled{
        display: block;
    }
    
    /* Atribute Navigation
    =================================*/    
    .attr-nav > ul > li.dropdown ul.dropdown-menu{
        margin-top: 0;
        margin-left: 55px;
        width: 250px;
        left: -250px;
    }
    
    /* Menu Center
    =================================*/
    nav.navbar.bootsnav.menu-center .container{
        position: relative;
    }
    
    nav.navbar.bootsnav.menu-center ul.nav.navbar-center{
        float:none;
        margin: 0 auto;
        display: table;
        table-layout: fixed;
    }
    
    nav.navbar.bootsnav.menu-center .navbar-header,
    nav.navbar.bootsnav.menu-center .attr-nav{
        position: absolute;
    }
    
    nav.navbar.bootsnav.menu-center .attr-nav{
        right: 15px;
    }
    
    /* Navbar Brand top
    =================================*/
    nav.bootsnav.navbar-brand-top .navbar-header{
        display: block;
        width: 100%;
        text-align: center;
    }
    
    nav.bootsnav.navbar-brand-top ul.nav > li.dropdown > ul.dropdown-menu{
        margin-top: 2px;
    }

    nav.bootsnav.navbar-brand-top li.dropdown.megamenu-fw ul.dropdown-menu{
        margin-top: -5px !important;
    }
    
    nav.bootsnav.navbar-brand-top .navbar-header .navbar-brand{
        display: inline-block;
        float: none;
        margin: 0;
    }
    
    nav.bootsnav.navbar-brand-top .navbar-collapse{
        text-align: center;
    }
    
    nav.bootsnav.navbar-brand-top ul.nav{
        display: inline-block;
        float: none;
        margin: 0 0 -5px 0;
    }
    
    /* Navbar Center
    =================================*/
	nav.bootsnav.brand-center .navbar-header{
        display: block;
        width: 100%;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
    }
    
    nav.bootsnav.brand-center .navbar-brand{
        display: inline-block;
        float: none;
		margin-left:5px;
    }
    
    nav.bootsnav.brand-center .navbar-collapse{
        text-align: center;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
    }
    
    nav.bootsnav.brand-center ul.nav > li.dropdown > ul.dropdown-menu{
        margin-top: 2px;
    }
    
    nav.bootsnav.brand-center ul.nav > li.dropdown.megamenu-fw > ul.dropdown-menu{
        margin-top: 0;
    }
    
    nav.bootsnav.brand-center .navbar-collapse .col-half{
        width: 50%;
        float: left;
        display: block;
    }
    
    nav.bootsnav.brand-center .navbar-collapse .col-half.left{
        text-align: right;
        padding-right: 100px;
    }
    
    nav.bootsnav.brand-center .navbar-collapse .col-half.right{
        text-align: left;
        padding-left: 100px;
    }
    
    nav.bootsnav.brand-center ul.nav{
        float: none !important;
        margin-bottom: -5px !important;
        display: inline-block !important;
    }
    
    nav.bootsnav.brand-center ul.nav.navbar-right{
        margin: 0;
    }
    
    nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.left{
        text-align: left;
        padding-right: 100px;
    }
    
    nav.bootsnav.brand-center.center-side .navbar-collapse .col-half.right{
        text-align: right;
        padding-left: 100px;
    }
	
    /* Navbar centered
    =================================*/  
	nav.bootsnav.navbar-centered{
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		margin-top:60px;
		width: 1170px;
		height:80px;
		border:none;
	}
	.content-parallax nav.bootsnav.navbar-centered{
		margin-top:0;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
	}
	nav.bootsnav.navbar-centered.navbar-fixed{
		position: fixed;
		width: 1170px;
		left:50%;
		margin-left:-585px;

	}

	nav.navbar.bootsnav.navbar-centered ul.nav .dropdown .dropdown-menu,
	nav.navbar.bootsnav.navbar-centered ul.nav .dropdown.megamenu-fw .dropdown-menu{
		margin-top: -5px !important;
	}
	nav.bootsnav.navbar-centered.navbar-centered-clear{
		margin-top:0;
		width:100%;
		left:0;
		top:0;
		margin-left:0;
	}

	nav.bootsnav.navbar-centered .navbar-brand{
		float:left;
		width:220px;
		height:80px;
		text-align:center;
	}
	
	nav.bootsnav.navbar-centered .navbar-brand img.logo{
		margin:0 auto;
	}
	
	nav.bootsnav.navbar-centered.navbar-fixed .attr-nav{
		margin-left:30px;
	}
	
	nav.bootsnav.navbar-centered.navbar-fixed .attr-nav > ul > li > a{
		background:#2b2b2b;
		padding: 28px 35px;
	}
	
    /* Navbar Sidebar
    =================================*/
    body.wrap-nav-sidebar .wrapper{
        padding-left: 270px; 
        overflow-x: hidden;
    }

    nav.bootsnav.navbar-sidebar{
        position: fixed;
        width: 260px;
        overflow: hidden;
        left: 0;
        padding:0 30px 0 0 !important;
        background: #fff;
		text-align:right;
		border-right:1px solid #eeeeee;
    }  
    
    nav.bootsnav.navbar-sidebar .scroller{
        width: 280px;
        overflow-y:auto;
        overflow-x: hidden;
    }

    nav.bootsnav.navbar-sidebar .container-fluid,
    nav.bootsnav.navbar-sidebar .container{
        padding: 0 !important;
    }

    nav.bootsnav.navbar-sidebar .navbar-header{
        float: none;
        display: block;
        width: 260px;
        padding: 0 30px 0 0;
        margin: 60px 0 0 0 !important;
    }
    nav.navbar-sidebar .navbar-brand{
        margin-bottom: 20px !important;
		padding:0 !important;
    }
    nav.bootsnav.navbar-sidebar .navbar-header .logo{
		padding: 0;
		margin-right:-10px;
	}
    nav.bootsnav.navbar-sidebar .navbar-collapse{
        padding: 0 !important;
        width: 270px;
		padding-right:0;
    }

    nav.bootsnav.navbar-sidebar ul.nav{
        float: right;
        display: block;
        width: 100%;
        padding: 0 30px 0 0!important;
        margin: 0;
		text-align:right;
    }

    nav.bootsnav.navbar-sidebar ul.nav li{
        float: none !important;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav > li > a{
        padding: 15px 0 15px 0;
        font-size:14px;
		font-weight:400;
		text-transform:uppercase;
		color:#333;
		letter-spacing:2px;
    }

	nav.navbar.bootsnav.navbar-sidebar ul.nav li.active > a:hover,
	nav.navbar.bootsnav.navbar-sidebar ul.nav li.active > a:focus,
	nav.navbar.bootsnav.navbar-sidebar ul.nav li.active > a{
		border-top:3px solid transparent;
	}

	nav.navbar.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content .title{
		font-size:14px;
	}
	nav.navbar.bootsnav.navbar-sidebar ul.dropdown-menu li a,
	nav.navbar.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content .content ul.menu-col li a{
		font-size:14px;
	}
     nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > a.dropdown-toggle{
		padding-right:10px;
	 }
    nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > a.dropdown-toggle:after{
        font-family: 'FontAwesome';
		content:"\f067";
        position:relative;
		font-size:14px;
		padding-left:0;
		margin-top:-5px;
		margin-right:-10px;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu{
        left: 100%;
        top: 0;
        position: relative !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
        background-color: transparent;
        border: none !important;
        padding: 0;
        -moz-box-shadow: 0px 0px 0px;
        -webkit-box-shadow: 0px 0px 0px;
        -o-box-shadow: 0px 0px 0px;
        box-shadow: 0px 0px 0px;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav .megamenu-content .col-menu{
        border: none !important;
		text-align:right;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav > li.dropdown > ul.dropdown-menu{
        margin-bottom: 15px;
		text-align:right;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu{
        padding-left: 0;
        float: none;
        margin-bottom: 0;
		text-align:right;
    }
    
	nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li.dropdown{
		padding-right:20px;
	}
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a{
        padding:  10px 0;
        color: #333;
        border: none;
		text-transform:uppercase;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu ul.dropdown-menu{
        padding-left: 0;
        margin-top: 0;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li.dropdown > a:before{
        font-family: 'FontAwesome';
        content: "\f067";
        float: right;
		margin-right:-20px;
    }
    
    nav.bootsnav.navbar-sidebar ul.nav li.dropdown.on ul.dropdown-menu li.dropdown.on > a:before{
        content: "\f068";
    }
    
    nav.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content > li{
        padding: 0 20px 0 0 !important;
		text-align:right;
    }

    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu{
        display: block;
        float: none !important;
        padding: 0 !important;
        margin: 0;
        width: 100%;
		text-align:right;
    }
     nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu li{
        padding:0 !important;
    }    
    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title{
        padding: 12px 0;
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0px;
        margin-bottom: 0;
        cursor: pointer;
        color: #333;
		text-transform:uppercase;
    }
    
    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title:before{
        font-family: 'FontAwesome';
        content: "\f067";
        float: right;
		margin-right:-20px;
    }
    
    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu.on .title:before{
        content: "\f068";
    }
    
    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu{
		width:100%;
		display:block;
        border: none;
		text-align:right;
		padding:0;
    }
    
    nav.bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .content{
		width:100%;
		display:block;
        padding:0;
		text-align:right;
    }
    
    nav.navbar.bootsnav.navbar-sidebar ul.dropdown-menu.megamenu-content .content ul.menu-col li a{
        padding:10px 0;
		width:100%;
		display:block;
		text-align:right;
		color:#333;
    }
}

@media (min-width:1024px) and (max-width: 1200px) {
    nav.bootsnav.brand-center ul.nav > li{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
    nav.bootsnav.brand-center .col-half.left{
        padding-right: 80px !important;
    } 
    
    nav.bootsnav.brand-center .col-half.right{
        padding-left: 80px !important;
    }
}

@media (max-width: 992px) {
    /* Navbar Responsive
    =================================*/ 
    nav.navbar.bootsnav .navbar-brand    {
        display: inline-block;
        float: none !important;
        margin: 0 !important;
    }
    
    nav.navbar.bootsnav .navbar-header {
        float: none;
        display: block;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }
    
    nav.navbar.bootsnav .navbar-toggle {
        display: inline-block;
        float: left;
        margin-right: -200px;
        margin-top: 10px;
    }
    
    nav.navbar.bootsnav .navbar-collapse {
        border: none;   
        margin-bottom: 0;
    }
    
    nav.navbar.bootsnav.no-full .navbar-collapse{
        max-height: 350px;
        overflow-y: auto !important;
    }
    
    nav.navbar.bootsnav .navbar-collapse.collapse {
        display: none !important;
    }
    
    nav.navbar.bootsnav .navbar-collapse.collapse.in {
        display: block !important;
    }
    
    nav.navbar.bootsnav .navbar-nav {
        float: none !important;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0px -15px;
    }
    
    nav.navbar.bootsnav .navbar-nav > li {
        float: none;
    }
    
    nav.navbar.bootsnav li.dropdown a.dropdown-toggle:before{
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
        font-size: 16px;
        margin-left: 10px;
    }
    
    nav.navbar.bootsnav li.dropdown.on > a.dropdown-toggle:before{
        content: "\f107";
    }
    
    nav.navbar.bootsnav .navbar-nav > li > a{
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 10px 0;
        border-top: solid 1px #e0e0e0;
        margin-bottom: -1px;
    }
    
    nav.navbar.bootsnav .navbar-nav > li:first-child > a{
        border-top: none;
    }
    
    nav.navbar.bootsnav ul.navbar-nav.navbar-left > li:last-child > ul.dropdown-menu{
        border-bottom: solid 1px #e0e0e0;
    }
    
    nav.navbar.bootsnav ul.nav li.dropdown li a.dropdown-toggle{
        float: none !important;
        position: relative;
        display: block;
        width: 100%;
    }
    
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu{
        width: 100%;
        position: relative !important;
        background-color: transparent;
        float: none;
        border: none;
        padding: 0 0 0 15px !important;
        margin: 0 0 -1px 0 !important;
        -moz-box-shadow: 0px 0px 0px;
        -webkit-box-shadow: 0px 0px 0px;
        -o-box-shadow: 0px 0px 0px;
        box-shadow: 0px 0px 0px;
        -moz-border-radius: 0px 0px 0px;
        -webkit-border-radius: 0px 0px 0px;
        -o-border-radius: 0px 0px 0px;
        border-radius: 0px 0px 0px;
    }
    
    nav.navbar.bootsnav ul.nav li.dropdown ul.dropdown-menu  > li > a{
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 10px 0;
        color: #6f6f6f;
    }
    
    nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:hover,
    nav.navbar.bootsnav ul.nav ul.dropdown-menu li a:focus{
        background-color: transparent;
    }
    
    nav.navbar.bootsnav ul.nav ul.dropdown-menu ul.dropdown-menu{
        float: none !important;
        left: 0;
        padding: 0 0 0 15px;
        position: relative;
        background: transparent;
        width: 100%;
    }
    
    nav.navbar.bootsnav ul.nav ul.dropdown-menu li.dropdown.on > ul.dropdown-menu{
        display: inline-block;
        margin-top: -10px;
    }
    
    nav.navbar.bootsnav li.dropdown ul.dropdown-menu li.dropdown > a.dropdown-toggle:after{
        display: none;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title{
        padding: 10px 15px 10px 0;
        line-height: 24px;
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0px;
        margin-bottom: 0;
        cursor: pointer;
        border-bottom: solid 1px #e0e0e0;
        color: #6f6f6f;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu ul > li > a{
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 8px 0;
    }
    
   nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .title:before{
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
        font-size: 16px;
        margin-left: 10px;
       position: relative;
       right: -15px;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .title{
        border-bottom: none;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on:last-child .title{
        border-bottom: solid 1px #e0e0e0;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child ul.menu-col li:last-child a{
        border-bottom: none;
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu.on .title:before{
        content: "\f107";
    }
    
    nav.navbar.bootsnav .dropdown .megamenu-content .col-menu .content{
        padding: 0 0 0 15px;
    }
    
    nav.bootsnav.brand-center .navbar-collapse{
        display: block;
    }
    
    nav.bootsnav.brand-center ul.nav{
        margin-bottom: 0px !important;
    }
    
    nav.bootsnav.brand-center .navbar-collapse .col-half{
        width: 100%;
        float: none;
        display: block;
    }
    
    nav.bootsnav.brand-center .navbar-collapse .col-half.left{
        margin-bottom: 0;
    }
    
    nav.bootsnav .megamenu-content{
        padding: 0;
    }
    
    nav.bootsnav .megamenu-content .col-menu{
        padding-bottom: 0;
    }
    
    nav.bootsnav .megamenu-content .title{
        cursor: pointer;
        display: block;
        padding: 10px 15px;
        margin-bottom: 0;
        font-weight: normal;
    }
    
    nav.bootsnav .megamenu-content .content{
        display: none;
    }
    
    .attr-nav{
        position: absolute;
        right: 60px;
    }
    
    .attr-nav > ul{
        padding: 0;
        margin: 0 -15px -7px 0;
    }
    
    .attr-nav > ul > li > a{
        padding: 16px 15px 15px;
    }
    
    .attr-nav > ul > li.dropdown > a.dropdown-toggle:before{
        display: none;
    }
    
    .attr-nav > ul > li.dropdown ul.dropdown-menu{
        margin-top: 2px;
        margin-left: 55px;
        width: 250px;
        left: -250px;
        border-top: solid 5px;
    }
    
    .top-search .container{
        padding: 0 45px;
    }
    
    /* Navbar full Responsive
    =================================*/   
    nav.bootsnav.navbar-full ul.nav{
        margin-left: 0;
    }
    
    nav.bootsnav.navbar-full ul.nav > li > a{
        border: none;
    }
    
    nav.bootsnav.navbar-full .navbar-brand    {
        float: left !important;
        padding-left: 0;
    }
    
    nav.bootsnav.navbar-full .navbar-toggle {
        display: inline-block;
        float: right;
        margin-right: 0;
        margin-top: 10px;
    }
    
    nav.bootsnav.navbar-full .navbar-header {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    /* Navbar Sidebar
    =================================*/ 
    nav.navbar.bootsnav.navbar-sidebar .share{
        padding: 30px 15px;
        margin-bottom: 0;
    }
    
    /* Tabs
    =================================*/ 
    nav.navbar.bootsnav .megamenu-content.tabbed{
        padding-left: 0!important;
    }
    
    nav.navbar.bootsnav .tabbed > li{
        padding: 25px 0;
        margin-left: -15px !important;
    }
    
    /* Mobile Navigation
    =================================*/     
    body > .wrapper{
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    body.side-right > .wrapper{
        margin-left: 280px;
        margin-right: -280px !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile .navbar-collapse{
        position: fixed;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        display: block;
        background: #fff;
        z-index: 99;
        width: 280px;
        height: 100% !important;
        left: -280px;
        top: 0;
        padding: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    nav.navbar.bootsnav.navbar-mobile .navbar-collapse.in{
        left: 0;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav{
        width: 293px;
        padding-right: 0;
        padding-left: 15px;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav > li > a{
        padding: 15px 15px;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu > li > a{
        padding-right: 15px !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title{
        padding-right: 30px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a{
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile .navbar-collapse [class*=' col-'] {
        width: 100%;
    }
    
    nav.navbar.bootsnav.navbar-fixed .logo-scrolled{
        display: block !important;
    }

    nav.navbar.bootsnav.navbar-fixed .logo-display{
        display: none !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile .tab-menu,
    nav.navbar.bootsnav.navbar-mobile .tab-content{
        width: 100%;
        display: block;
    }
    
    .navbar.navbar-inverse.navbar-centered.navbar-fixed.dark.bootsnav .navbar-brand{
        height: 70px;
        margin-bottom: -17px !important;
    }
}

@media (max-width: 767px) {
    nav.navbar.bootsnav .navbar-header {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    nav.navbar.bootsnav .navbar-nav {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .attr-nav{
        right: 30px;
    }
    
    .attr-nav > ul{
        margin-right: -10px;
    }
    
    .attr-nav > ul > li > a{
        padding: 16px 10px 15px;
        padding-left: 0 !important;
    }
    
    .attr-nav > ul > li.dropdown ul.dropdown-menu{
        left: -275px;
    }
    
    .top-search .container{
        padding: 0 15px;
    }
    
    nav.bootsnav.navbar-full .navbar-collapse{
        left: 15px;
    }
    
    nav.bootsnav.navbar-full .navbar-header{
        padding-right: 0;
    }
    
    nav.bootsnav.navbar-full .navbar-toggle {
        margin-right: -15px;
    }
    
    nav.bootsnav.navbar-full ul.nav > li > a{
        font-size: 18px !important;
        line-height: 24px !important;
        padding: 5px 10px !important;
    }
    
    /* Navbar Sidebar
    =================================*/ 
    nav.navbar.bootsnav.navbar-sidebar .share{
        padding: 30px 15px !important;
    }
    
    /* Navbar Sidebar
    =================================*/ 
    nav.navbar.bootsnav.navbar-sidebar .share{
        padding: 30px 0 !important;
        margin-bottom: 0;
    }
    
    nav.navbar.bootsnav.navbar-mobile.navbar-sidebar .share{
        padding: 30px 15px !important;
        margin-bottom: 0;
    }
    
    /* Mobile Navigation
    =================================*/ 
    body.side-right > .wrapper{
        margin-left: 280px;
        margin-right: -280px !important;
    }
    
    nav.navbar.bootsnav.navbar-mobile .navbar-collapse{
        margin-left: 0;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav{
        margin-left: -15px;
    }
    
    nav.navbar.bootsnav.navbar-mobile ul.nav{
        border-top: solid 1px #fff;
    }
    
    li.close-full-menu{
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) {
    nav.bootsnav.navbar-full ul.nav{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}