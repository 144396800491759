/* ==============================================================

Template name : Inspired Multipurpose corporate and creative template
Categorie : Site template
Author : Nce18cex
Version : v1.0.0
Created : 20 August 2016
Last update : 15 Oktober 2016

-----------------------------------------------------------------
CSS structure
-----------------------------------------------------------------

01. Stylesheet
02. Container
03. Home App
04. Title head
05. Layerslider
06. Animate background
07. Intro
08. Inner head
09. Call to action
10. One page
11. Team
12. Video
13. jPlayer
14. Gallery
15. Shopping
16. Shopping cart
17. Clients
18. Blog
19. Aside
20. Comments
21. Contact
22. Restaurant
23. Testimoni
24. Parallax
25. Counter
26. Precess
27. Google Map
28. Pricing
29. Progress Bar
30. Search
31. Under construction
32. Maintenance
33. Error 404
34. Login and register
35. Custom search
36. Member area
37. Footer
38. Twitter Feed
40. Media responsive
41. Fontawesome
42. Icon and text
43. Pricing
44. Real Estate
45. Preloading
46. Back to top
47. Clear spacer
48. Custom left right spacer
49. Custom top spacer
50. Custom bottom spacer
51. Responsive
52. Demo
	
============================================================== */

/*
Stylesheet
=========================== */
/*
@import url('../fonts/FontAwesome/font-awesome.css');
@import url('../fonts/opensans/stylesheet.css');
@import url('overwrite.css');
@import url('animate.css');
@import url('custom-file-input.css');
@import url('owl.carousel.css');
@import url('owl.theme.css');
@import url('owl.transitions.css');
@import url('masonry.css');
@import url('flexslider.css');
@import url('prettify.css');
@import url('prettyPhoto.css');
@import url('bootsnav.css');
@import url('ion.rangeSlider.css');
@import url('datetimepicker.css');
@import url('jquery.background-video.css');
*/

/*
Container
=========================== */
.contain-wrapp{
	position:relative;
	padding:80px 0;
}

.gray-container{
	background:#fbfbfb;
	border-top:1px solid #ececec;
	border-bottom:1px solid #ececec;
}

.gray-container.noborder{
	border-top:none;
	border-bottom:none;
}

.dark-container{
	background:#1a1a1a;
	color:#bababa;
}

.restaurant-wrapp{
	padding-bottom:0;
	background:#fff url(../img/pizza-bg.png) no-repeat bottom left;
}

.half-container{
	position:relative;
	background:url(../img/half-containerbg.jpg) repeat-y top center;
	padding:60px 0;
}

.half-container .row {
  margin-right: -60px;
  margin-left: -60px;
}

.half-container .row .row{
  margin-right: -15px;
  margin-left: -15px;
}

.half-container .col-xs-6,
.half-container .col-sm-6,
.half-container .col-md-6,
.half-container .col-lg-6 {
	padding-right: 60px;
	padding-left: 60px;
}

.half-column-wrapp{
	position:relative;
	float:left;
	width:100%;
	padding:0;
	background:#1a1a1a;
	color:#bababa;
}

.half-column-wrapp h1,
.half-column-wrapp h2,
.half-column-wrapp h3,
.half-column-wrapp h4,
.half-column-wrapp h5,
.half-column-wrapp h6{
	color:#fff;
}

.half-column-wrapp .half-column{
	position:relative;
	width:50%;
	padding:0;
	margin:0;
	background:#1a1a1a;
}

.half-column-wrapp .half-column.pull-right{
	float:left !important;
}

.half-column-wrapp .half-column.column-content {
	padding:60px 60px 40px 60px;
}

.fullwidth{
	width:100%;
	margin:0;
	padding:0;
}

.fullwidth .col-xs-1,
.fullwidth .col-sm-1,
.fullwidth .col-md-1,
.fullwidth .col-lg-1,
.fullwidth .col-xs-2,
.fullwidth .col-sm-2,
.fullwidth .col-md-2,
.fullwidth .col-lg-2,
.fullwidth .col-xs-3,
.fullwidth .col-sm-3,
.fullwidth .col-md-3,
.fullwidth .col-lg-3,
.fullwidth .col-xs-4,
.fullwidth .col-sm-4,
.fullwidth .col-md-4,
.fullwidth .col-lg-4,
.fullwidth .col-xs-5,
.fullwidth .col-sm-5,
.fullwidth .col-md-5,
.fullwidth .col-lg-5,
.fullwidth .col-xs-6,
.fullwidth .col-sm-6,
.fullwidth .col-md-6,
.fullwidth .col-lg-6,
.fullwidth .col-xs-7,
.fullwidth .col-sm-7,
.fullwidth .col-md-7,
.fullwidth .col-lg-7,
.fullwidth .col-xs-8,
.fullwidth .col-sm-8,
.fullwidth .col-md-8,
.fullwidth .col-lg-8,
.fullwidth .col-xs-9,
.fullwidth .col-sm-9,
.fullwidth .col-md-9,
.fullwidth .col-lg-9,
.fullwidth .col-xs-10,
.fullwidth .col-sm-10,
.fullwidth .col-md-10,
.fullwidth .col-lg-10,
.fullwidth .col-xs-11,
.fullwidth .col-sm-11,
.fullwidth .col-md-11,
.fullwidth .col-lg-11,
.fullwidth .col-xs-12,
.fullwidth .col-sm-12,
.fullwidth .col-md-12,
.fullwidth .col-lg-12{
	margin:0;
	padding:0;
}

.fullwidth.aside-container .col-xs-4,
.fullwidth.aside-container .col-sm-4,
.fullwidth.aside-container .col-md-4,
.fullwidth.aside-container .col-lg-4{
	width: 33.33333%;
}

.container-full{
	width:100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.container-full .row{
	margin-right: 0;
	margin-left: 0;
}

#slider-wrapper{
	position:relative;
	overflow:hidden;
	padding:0;
	margin:0;
}

.slider-content{
	color:#fff;
	background: rgba(34,34,34,0.2);
	background: -moz-linear-gradient(top, rgba(34,34,34,0.2) 0%, rgba(34,34,34,0) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(34,34,34,0.2)), color-stop(100%, rgba(34,34,34,0)));
	background: -webkit-linear-gradient(top, rgba(34,34,34,0.2) 0%, rgba(34,34,34,0) 100%);
	background: -o-linear-gradient(top, rgba(34,34,34,0.2) 0%, rgba(34,34,34,0) 100%);
	background: -ms-linear-gradient(top, rgba(34,34,34,0.2) 0%, rgba(34,34,34,0) 100%);
	background: linear-gradient(to bottom, rgba(34,34,34,0.2) 0%, rgba(34,34,34,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#222222', GradientType=0 );
}

.container-wrapper{
	background:#fff;
	padding-left:0;
	padding-right:0;
	border:1px solid #e3e3e3;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    overflow: hidden;
}

.container-wrapper .top-link{
	margin-right:-13px;
}

.container-wrapper .row{
	padding-left:30px;
	padding-right:30px;
}

.top-wrapp .row,
.container-wrapper .row .row{
	padding-left:0;
	padding-right:0;
}

.container-wrapper .row.wrapp{
	padding-top:60px;
	padding-bottom:60px;
}

.container-wrapper footer{
	padding-top:60px;
}

.subfooter{
	margin:0;
}

.photography-title{
	position:absolute;
	width:auto;
	top:40%;
	left:-16.66666667%;
	text-transform:uppercase;
	font-size:48px;
	line-height:48px;
	letter-spacing:2px;
	font-weight:300;
	background:#fff;
	z-index:2;
	padding:20px 30px 20px 20px;
}

.photography-title h1{
	font-size:120px;
	line-height:120px;
	margin-bottom:0;
}

.column-box{
	padding-top:80px;
	padding-bottom:80px;
}

.textline-light{
	font-size:52px;
	line-height:64px;
	font-weight:300;
	color:#fff;
}

/*
Home App
=========================== */
.home-app{
	background:url(../img/gray-bg.jpg) no-repeat top center;
    padding-top: 160px;
}

.home-app .content-home{
    font-size: 14px;
	line-height:32px;
    padding-top: 60px;
    letter-spacing: 2px;
	color:#333;
}

.btn-app{
    margin-top: 25px;
    display: block;
}

.home-app .content-home .title{
    font-size: 62px;
    line-height: 76px;
	color:#212121;
}

.home-app .wrap-slider{
    width: 360px;
    position: relative;
    display: table;
    margin: auto;
}

.home-app .img-wrapslider{
    width: 100%;
}

.home-app .slider-app{
    background-color: #999;
    position: absolute;
    display: block;
    width: 256px;
    height: 455px;
    top: 93px;
    left: 47px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
}

.home-app .slider-app .owl-item,
.home-app .slider-app .item{
    margin: 0 !important;
    padding: 0 !important;
}

.app-sparator-wrapp{
	position:relative;
	width:100%;
	height:160px;
}

.app-sparator-center{
	position:absolute;
	width:60%;
	height:1px;
	border-bottom:2px dashed #ccc;
	left:20%;
	top:80px;
}

.app-sparator-vertical{
	position:absolute;
	width:1;
	height:80px;
	border-left:2px dashed #ccc;
	color:#ccc;
	font-size:18px;
}

.app-sparator-vertical.top-left{
	left:20%;
	top:0;
}

.app-sparator-vertical.top-right{
	left:80%;
	top:0;
}

.app-sparator-vertical.bottom-left{
	left:20%;
	bottom:0;
}

.app-sparator-vertical.bottom-right{
	left:80%;
	bottom:0;
}

.app-sparator-vertical.top-left .fa,
.app-sparator-vertical.top-right .fa{
	position:absolute;
	top:-10px;
	left:0;
	margin-left:-6.5px;
}

.app-sparator-vertical.bottom-left .fa,
.app-sparator-vertical.bottom-right .fa{
	position:absolute;
	bottom:-11px;
	left:0;
	margin-left:-6.5px;
}

/*
Title head
=========================== */
.title-head{
	position:relative;
	display:block;
	width:100%;
	font-size:24px;
	line-height:36px;
	font-weight:300;
	color:#333333;
	margin-bottom:40px;
	padding:0 0 10px 0;
}

.title-head h4{
	text-transform:uppercase;
	margin-bottom:20px;
	font-size:18px;
	line-height:14px;
}

.title-head.restaurant-head{
	text-transform:uppercase;
	font-size:18px;
	line-height:32px;
	font-weight:700;
}

.title-head.restaurant-head h4{
	text-transform:none;
	font-family: 'Allura', cursive;
	font-size:36px;
	line-height:22px;
}

.title-head:before{
	display:block;
	content: "";
	position:absolute;
	width:100%;
	height:1px;
	background:#cecece;
	bottom:0;
	left:0;
}

.title-head:after{
	display:block;
	content: "";
	position:absolute;
	width:60px;
	height:3px;
	bottom:-1px;
	left:0;
}

.title-head.centered{
	text-align:center;
}

.title-head.centered:before{
	width:260px;
	left:50%;
	margin-left:-130px;
}


.title-head.centered:after{
	left:50%;
	margin-left:-30px;
}

.title-head.pull-right{
	float:none !important;
	text-align:right;
}

.title-head.pull-right:after{
	left:100%;
	margin-left:-60px;
}

.vertical-link{
	position:fixed;
	display:block;
	color:#333333;
	height:60px;
	width:280px;
	font-size:14px;
	line-height:28px;
	letter-spacing:2px;
	text-transform:uppercase;
	text-align:center;
	padding:15px 20px;
	vertical-align:midle;
    -ms-transform: rotate(270deg); /* IE 9 */
    -webkit-transform: rotate(270deg); /* Safari */
    transform: rotate(270deg); /* Standard syntax */
	z-index:9;
}

.vertical-link.left-link{
	left:0;
	top:0;
	margin-left:-100px;
	margin-top:150px;
}

.vertical-link.right-link{
	right:-100px;
	bottom:160px;
	margin-left:0;
	margin-top:0;
}

.vertical-link.top-link{
	right:20px;
	top:10px;
	margin-left:0;
	margin-top:0;
    -ms-transform: none; /* IE 9 */
    -webkit-transform: none; /* Safari */
    transform: none; /* Standard syntax */
}

/*
Layerslider
=========================== */
#layerslider .btn{
	padding:20px 30px;
	font-size:24px;
	white-space: nowrap;
}

#layerslider .btn-lg{
	padding:30px 40px;
	font-size:32px;
	white-space: nowrap;
}

#layerslider .btn-sm{
	padding:15px 20px;
	font-size:14px;
	white-space: nowrap;
}

.headline{
	font-size:96px;
	white-space: nowrap;
	letter-spacing:4px;
	color:#2a2a2a;
}

.headline1{
	font-size:98px;
	text-transform:uppercase;
	white-space: nowrap
}

.headline1.h-2x{
	font-size:200px;
}

.headline2{
	font-size:56px;
	text-transform:uppercase;
	font-weight:400;
	white-space: nowrap;
}

.headline3{
	white-space: nowrap;
	color:#fff;
	font-size:38px;
	line-height:38px;
	padding:25px 40px;
}

.headline4{
	font-size:96px;
	text-transform:uppercase;
	white-space: nowrap;
	color:#fff;	
	letter-spacing:4px;
}

.headline5{
	color:#fff;	
	width:1600px;
	font-size:92px;
	line-height:120px;
}

.headline6{
	font-size:240px;
	line-height:240px;
	color:#fff;	
	text-transform:uppercase;
	letter-spacing:-2px;
	white-space: nowrap;
}

.headline7{
	font-size:92px;
	white-space: nowrap;
	color:#fff;	
	font-family: 'Allura', cursive;
	font-weight:400;
}

.headline3.default-bg{
	background:#333!important;
}

.textline,
.textline-icon{
	background:#333;
	color:#fff;
	font-size:28px;
	line-height:28px;
	white-space: nowrap;
	padding-left:35px;
}

.textline{
	padding:15px 35px;
}

.textline-icon.left{
	padding-left:0;
	padding-right:35px;
}

.textline-icon .fa{
	padding:15px 0;
	font-size:24px;
	margin-left:15px;
	width:65px;
	text-align:center;
}

.textline-icon.left .fa{
	margin-left:0;
	margin-right:15px;
}

.textline-default,
.textline-white{
	font-size:24px;
	line-height:42px;
	color:#333;
	width:950px;
}

.textline-white{
	font-size:24px;
	line-height:42px;
	color:#fff;
	width:1140px;
}

.textline-lg{
	font-size:52px;
	font-weight:300;
	color:#fff;
	white-space: nowrap;
}

.textline-center{
	font-size:52px;
	text-transform:uppercase;
	color:#fff;
	white-space: nowrap;
	letter-spacing:2px;
}

.textline1{
	font-size:24px;
	text-transform:uppercase;
	font-weight:700;
}

.textline2{
	font-size:36px;
	text-transform:uppercase;
	color:#333;
	letter-spacing:1.5px;
}

.textline3{
	font-size:56px;
	text-transform:uppercase;
	color:#fff;	
	letter-spacing:10px;
	white-space: nowrap;
}

.text-white{
	color:#fff;	
}

.text-default{
	color:#333;	
}

.contant-caption{
	width:1300px;
	height:750px;
	border-radius:80% 0 0 0;
	background:rgba(0, 0, 0, 0.6);
}

/*
Animate background
=========================== */
.large-header {
	position: relative;
	width: 100%;
	background: #333;
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	z-index: 1;
}

.animatedBg .large-header {
	background-image: url('../img/slider/header-bg.jpg');
}

.main-title {
	position: absolute;
	margin: 0;
	padding: 0;
	color:#fff;
	text-align: center;
	width:100%;
	height:100%;
	top: 0;
	left: 0;
	z-index:4;
	font-weight:300;
	text-transform: uppercase;
	font-size:24px;
	line-height:32px;	
	letter-spacing: 5px;
	padding-top:160px;
}

.animatedBg .main-title h1{
	text-transform: uppercase;
	font-size: 56px;
	letter-spacing: 5px;
	color:#fff;
}

/*
Intro
=========================== */
.intro-wrapp{
	position:relative;
	padding:40px 0 25px 0;
	border-top:1px solid #fff;
	border-bottom:1px solid #fff;
	background: rgba(248,248,248,1);
	background: -moz-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,248,248,1)), color-stop(100%, rgba(243,243,243,1)));
	background: -webkit-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background: -o-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background: -ms-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background: linear-gradient(to bottom, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f3f3f3', GradientType=0 );
	background-image:url(../img/page-header.jpg);
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
}

.intro-wrapp ul.intro-icon-line{
	float:left;
	width:100%;
	padding:0;
	margin:0;
}

.intro-wrapp ul.intro-icon-line li{
	position:relative;
	float:left;
	list-style:none;
	width:36%;
	padding:40px 40px 10px 40px;
	margin:0;
}

.intro-wrapp ul.intro-icon-line li:first-child{
	width:32%;
	padding-left:0;
}

.intro-wrapp ul.intro-icon-line li:last-child{
	width:32%;
	padding-right:0;
}

.intro-wrapp ul.intro-icon-line > li + li:before {
	display:block;
	background:url(../img/box-line.png) no-repeat 0 0;
	content: "";
	position:absolute;
	width:2px;
	height:121px;
	top:0;
	left:0;
}

.intro-wrapp ul.intro-icon-line li .col-icon,
.intro-wrapp ul.intro-icon-line li .col-icon p{
	margin-bottom:0;
}

.intro-wrapp:before{
	display:block;
	content: "";
	position:absolute;
	width:100%;
	height:1px;
	background:#cecece;
	top:0;
	left:0;
}

.intro-wrapp:after{
	display:block;
	content: "";
	position:absolute;
	width:100%;
	height:1px;
	background:#cecece;
	bottom:-2px;
	left:0;
}

.title-intro{
	position:relative;
	display:block;
	font-size:24px;
	line-height:40px;
	font-weight:300;
	color:#333;
	margin-bottom:60px;
	padding-bottom:20px;
}

.title-intro h2{
	position:relative;
	font-size:54px;
	line-height:72px;
	font-weight:700;
	font-family: 'Old Standard TT', serif;
	letter-spacing: 0;
	padding-left:40px;
}

.title-intro h2:before{
	position:absolute;
	font-size:36px;
	line-height:36px;
	content: "";
	font-family: 'FontAwesome';
	content: "\f10d";
	top:0;
	left:0;
}

.title-intro:before{
	display:block;
	content: "";
	position:absolute;
	width:100%;
	height:1px;
	background:#cecece;
	bottom:0;
	left:0;
}

.title-intro:after{
	display:block;
	content: "";
	position:absolute;
	width:60px;
	height:3px;
	bottom:-1px;
	left:0;
}

.title-intro h2 span{
	font-weight:700;
}

.absolute-title{
	position:relative;
	width:100%;
	height:100%;
	vertical-align:midle;
	text-align:center;
	font-family: 'Old Standard TT', serif;
	padding:30px 0;
	margin:0;
	font-size:86px;
	line-height:92px;
}

/*
Inner head
=========================== */
.inner-head{
	position:relative;
	display:block;
	width:100%;
	background-color: rgba(248,248,248,1);
	background-color: -moz-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background-color: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,248,248,1)), color-stop(100%, rgba(243,243,243,1)));
	background-color: -webkit-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background-color: -o-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background-color: -ms-linear-gradient(top, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	background-color: linear-gradient(to bottom, rgba(248,248,248,1) 0%, rgba(243,243,243,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f3f3f3', GradientType=0 );
	background-image:url(../img/page-header.jpg);
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
	padding:60px 0;
	margin-bottom:0;
	text-align:center;
	border-bottom:2px solid #eaeaea;
}

.inner-head i{
	width:47px;
	height:47px;
	margin:7px 20px 0 0;
	border:2px solid #fff;
	text-align:center;
	overflow:hidden;
	font-size:24px;
	line-height:42px;
	border-radius:2px;
}

.inner-head h1, .inner-head  h2, .inner-head  h3, .inner-head  h4 , .inner-head  h5, .inner-head  h6{
	font-size:48px;
	line-height:56px;
	text-transform:uppercase;
	margin-bottom:15px;
}

.inner-head h1 span, .inner-head  h2 span, .inner-head  h3 span, .inner-head  h4 span, .inner-head  h5 span, .inner-head  h6 span{
	font-weight:300;
}

.inner-head .breadcrumb {
	margin-bottom:0;
}

/*
Call to action
=========================== */
.cta-wrapp{
	display:block;
	background:#fbfbfb;
	border-top:1px solid #ececec;
	border-bottom:1px solid #ececec;
	padding:60px 0 45px 0;
}

.cta-wrapp h1,
.cta-wrapp h2,
.cta-wrapp h3,
.cta-wrapp h4,
.cta-wrapp h5,
.cta-wrapp h6{
	vertical-align:middle;
	margin-top:10px;
}

.cta-wrapp.text-center{
	padding:60px 0 45px 0;
}

.cta-wrapp.text-center h1,
.cta-wrapp.text-center h2,
.cta-wrapp.text-center h3,
.cta-wrapp.text-center h4,
.cta-wrapp.text-center h5,
.cta-wrapp.text-center h6{
	margin-top:0;
}

.cta-inverse{
	background:#1a1a1a;
	border-top:1px solid #1a1a1a;
	border-bottom:1px solid #1a1a1a;
	color:#bababa;
}

.cta-primary,
.cta-inverse{
	color:#fff;
}

.cta-primary .input-group,
.cta-inverse .input-group{
	margin:20px 0 10px 0;
}

.cta-primary .form-control,
.cta-inverse .form-control{
	border:none;
	background:#fff;
}

/*
One page
=========================== */
.cd-section .container{
	height: 100vh;
	color:#fff;
	padding-top:140px;
}


.cd-section .container .form-control {
	border-color:#fff;
}

.scrolltop{
	overflow:hidden;
	display:block;
	height:auto;
	margin:50px 0 20px 0;
	padding:0 20px;
}

.scrolltop h2{
	font-size:78px;
	line-height:100px;
	color:#fff;
	font-weight:300;
	text-shadow: rgba(3, 3, 3, 0.2) 3px 1px 0px;
}

.cd-vertical-nav{
	margin:0;
	padding:0;
}

.cd-vertical-nav li{
	list-style:none;
	margin:0;
	padding:0;
}

.cd-vertical-nav a,
.cd-vertical-nav a:hover,
.cd-vertical-nav a:focus{
	width:50px;
	height:50px;
	color:#fff;
	border:4px solid #fff;
	border-radius:2px;
	text-align:center;
	line-height:43px;
	font-size:24px;
}

.cd-vertical-nav a.cd-prev{
	line-height:39px;
}

.cd-section:first-of-type > div {
	background-image: url(../img/onepageBg/bg01.jpg);
}

.cd-section:nth-of-type(2) > div {
	background-image: url(../img/onepageBg/bg02.jpg);
}

.cd-section:nth-of-type(3) > div {
	background-image: url(../img/onepageBg/bg03.jpg);
}

.cd-section:nth-of-type(4) > div {
	background-image: url(../img/onepageBg/bg04.jpg);
}

.cd-section:nth-of-type(5) > div {
	background-image: url(../img/onepageBg/bg05.jpg);
}

.onepage-wrapp{
	background: url(../img/man01.jpg) no-repeat top right fixed;
	background-size:cover;
	position:relative;
	margin:0;
}

.onepage-wrapp .logo{
	position:absolute;
	width:160px;
	height:49px;
	top:30px;
	left:20px;
}

.onepage-column{
	background:none;
	padding:80px 0;
}

.onepage-column .fullwidth{
	float:left;
	width:100%;
}

.onepage-column h1{
	font-size:180px;
	line-height:150px;
	font-weight:300;
	text-transform:uppercase;
	color:#212121;
}

.wrap-menu-page{
    display: block;
    position:fixed;
	bottom:0;
	right:0;
	width:100%;
    z-index:2;
    background:rgba(0,0,0,0.8);
}

.toggle-mobile{
    display: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 60px;
    top: 0;
    left: 50%;
    margin-left: -30px;
    background:rgba(0,0,0,0.8);
    color: #fff;
    font-size: 30px;
    padding-top: 15px;
}

.fixed-nav-tabs{
    display: inline-block;
}

ul.nav.fixed-nav-tabs{
    border-right:1px solid #4e4e4e;
}

ul.nav.fixed-nav-tabs > li{
	float:left;
}

ul.nav.fixed-nav-tabs > li > a{
	margin:0;
	border-radius:0;
	background:none;
	border:1px solid transparent;
	border-left:1px solid #4e4e4e;
}

.onepage-tab-content-wrapp{
	float:left;
	width:100%;
	margin:0;
	padding:0;
	background:#fff;
	border-radius:2px;
}

.onepage-half-column{
	float:left;
	width:50%;
	margin:0;
	padding:0;
}

.onepage-tab-content{
	padding:40px;
}

/*
Team
=========================== */
.wrap-team{
    position: relative;
    display: block;
    border: solid 1px #dfdfdf;
    text-align: center;
    background-color: #fff;
    margin-top: 85px;
	margin-bottom:30px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.wrap-team.standard{
	margin-top:0;
}

.wrap-team.standard .img-wrapper{
	padding:10px;
}

.wrap-team .content{
    padding: 25px 30px;
}

.wrap-team.standard .content{
    padding: 0 10px 5px 10px;
}

.wrap-team svg{
    width: 170px;
    height: 170px;
    margin-top: -85px;
    margin-bottom: -30px;
}

.wrap-team .name{
	margin-top:10px;
}

.wrap-team .name small{
    display: block;
    margin-top: 15px;
    color: #999;
}

.wrap-team p:last-child{
    margin-bottom: 0;
}

.wrap-team .share{
    background-color: #f9f9f9;
    padding: 20px 20px 15px 20px;
    border-top: solid 1px #dfdfdf;
}

.wrap-team .share ul{
	margin:0;
	padding:0;
	display:block;
	text-align:center;
}

.wrap-team .share ul li{
	list-style:none;
	margin:0;
	padding:0 4px;
	display:inline-block;
}

.team-wrapper{
	float:left;
	width:100%;
	margin-bottom:30px;
	padding:0;
	background:#e5e8eb;
}

.team-row{
	position:relative;
	float:left;
	width:50%;
	overflow:hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.team-row:hover{
	background:#f5f4f2;
}

.team-column,
.team-detail{
	overflow:hidden;
	float:left;
	width:50%;
	padding:0;
	margin:0;
	text-align:center;
}

.team-column img{
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.team-row:hover .team-column img{
	filter: blur(2px);
	-webkit-filter: blur(2px);
	-moz-filter: blur(2px);
	-o-filter: blur(2px);
	-ms-filter: blur(2px);
}

.team-detail{
	padding:10% 0;
}

.team-detail h5{
	margin-bottom:5px;
}

.team-detail span{
	cursor:pointer;
	display:block;
	margin-bottom:15px;
}

.team-frame{
	border:5px solid rgba(185, 187, 190, 0.5);
	position:absolute;
	display:block;
	content: "";
	width:100%;
	height:100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.team-network{
	position:relative;
	display:block;
	margin:0;
	padding:0;
	z-index:4;
}

.team-network li{
	display:inline;
	list-style:none;
	padding:0;
	margin:0;
}

/*
Video
=========================== */
.video-wrapper{
	position:relative;
	background-color:#212121;
	display:block;
    width: 100%;
	overflow:hidden;
	margin:0;
	padding:0;
	color:#fff;
}

.half-column-wrapp .video-wrapper{
    z-index: 2;
}

.video-wrapper .play-icon{
	position:absolute;
	background:rgba(0, 0, 0, 0.4);
	width:160px;
	height:160px;
	border-radius:50%;
	color:rgba(51, 51, 51, 0.7);
	z-index:4;
	text-align:center;
	overflow:hidden;
	font-size:58px;
	line-height:114px;
	left:50%;
	top:50%;
	margin:-80px 0 0 -80px;
	padding:20px;
}

.video-wrapper .play-icon.small{
    -ms-transform: scale(0.8,0.8); /* IE 9 */
    -webkit-transform: scale(0.8,0.8); /* Safari */
    transform: scale(0.8,0.8);
}

.video-wrapper .play-icon:hover{
	background:rgba(255, 255, 255, 0.4);
	-webkit-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.75);
}

.video-wrapper .play-icon a{
	background:rgba(255, 255, 255, 0.7);
	display:inline-block;
	width:120px;
	height:120px;
	border-radius:50%;
	padding-left:10px;
	color:rgba(51, 51, 51, 0.8);
}

.video-wrapper .play-icon:hover a{
	color:rgba(255, 255, 255, 1);
}

.video-wrapper img{
	width: 100%;
	position: absolute;
	z-index: 1;
    left: 0;
    top: 0;
}

.video-wrapper .jp-jplayer{
    opacity: 0;
    filter: alpha(opacity=0);
}

.video-wrapper .jp-jplayer.on{
    opacity: 1;
    filter: alpha(opacity=100);
}

.jp-jplayer.video img{
    display: none;
}

.video-wrapper .jp-audio{
    display: none;
    background: #333;
    background: rgba(0,0,0,0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

/*
jPlayer
=========================== */
.jp-audio{
    padding: 25px;
    background-color: #f0f0f0;
}

.jp-progress {
    overflow: hidden;
    background-color: #ddd; 
}

.jp-audio .jp-progress {
    height: 15px;
    width: 100%;
}
    
.jp-seek-bar {
    background: #666;
    width: 0px;
    height: 100%;
    cursor: pointer; 
}    
    
.jp-play-bar {
    width: 0px;
    height: 100%; 
}    

.jp-volume-bar {
    background: #666;
    width: 100%;
    height: 15px;
    cursor: pointer; 
}

.jp-volume-bar-value {
    width: 0px;
    height: 15px; 
}
    
.option{
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
}    

table.table-audio{
    width: 100%;
}

table.table-audio tr td{
    padding: 0 5px;
    margin: 0;
    text-align: center;
}

table.table-audio tr td .fa{
    font-size: 18px;
}

table.table-audio .toggle-play{
    width: 30px;
}

table.table-audio .time{
    width: 50px;
}

table.table-audio .toggle-mute{
    width: 30px;
}

table.table-audio .volume-bar{
    width: 50px;
}

/*
Gallery
=========================== */
.filter-items{
	display:block;
	margin:0 0 30px 0;
	padding:0;
	text-align:center;
}

.filter-items.pull-left{	
	float:none!important;
	text-align:left;
}

.filter-items.pull-right{	
	float:none!important;
	text-align:right;
}

.filter-wrapp{
	background:#fff;
	border-bottom:1px solid #d4d4d4;
	padding:0 20px 0 20px;
	overflow:hidden;
}

.filter-items li{
	position:relative;
	display:inline-block;
	list-style:none;
	margin:0;
	padding:0;
	color:#2b2b2b;
	font-size:16px;
}

.filter-items.filter-wrapp li{
	margin:0 -2px -1px -2px;
}

.filter-items li a{
	display:block;
	border:1px solid #d5d5d5;
	color:#333;
	font-weight:700;
	text-decoration:none;
	padding:10px 20px;
	border-radius:2px;
	-webkit-box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.06);
	-moz-box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.06);
	box-shadow: 0px 0px 6px -2px rgba(0,0,0,0.06);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.filter-items li a:hover,
.filter-items li a.active,
.filter-items li a.active:hover,
.filter-items li a.active:focus,
.filter-items li a.active:active{
	color:#fff;
}

.filter-items.pull-left.text-only li{
	margin-right:20px;
}

.filter-items.pull-right.text-only li{
	margin-left:20px;
}

.filter-items.text-only li a,
.filter-items.text-only li a.active{
	background:none !important;
	color:#333;
	font-weight:400;
	border:none;
	padding:0 0 5px 0;
	border:none;
	border-bottom:1px solid transparent;
	border-radius:0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.filter-items.text-only li a:hover,
.filter-items.text-only li a:focus,
.filter-items.text-only li a:active,
.filter-items.text-only li a.active:hover,
.filter-items.text-only li a.active:focus,
.filter-items.text-only li a.active:active{
	background:none !important;
}

.filter-wrapp li a,
.filter-wrapp li a:hover,
.filter-wrapp li a:focus,
.filter-wrapp li a:active{
	border:1px solid transparent;
	border-right:1px solid #d5d5d5;
	margin:0;
	padding:15px 30px;
	border-radius:0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.filter-wrapp li:first-child a{
	border-left:1px solid #d5d5d5;
}

.img-wrapper{
	position:relative;
	overflow:hidden;
    display: block;
	cursor:pointer;
	border-radius:0;
}

.img-wrapper.half-column{
	float:left;
	width:50%;
}

.img-wrapper img{
	max-width:100%;
}

.img-wrapper img.img-responsive{
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.img-caption{
    position: absolute;
    bottom:0;
    left: 0;
    display: table;
    width: 100%;

background: rgba(26,26,26,0);
background: -moz-linear-gradient(top, rgba(26,26,26,0) 0%, rgba(26,26,26,0.3) 50%, rgba(26,26,26,0.4) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(26,26,26,0)), color-stop(50%, rgba(26,26,26,0.3)), color-stop(100%, rgba(26,26,26,0.4)));
background: -webkit-linear-gradient(top, rgba(26,26,26,0) 0%, rgba(26,26,26,0.3) 50%, rgba(26,26,26,0.4) 100%);
background: -o-linear-gradient(top, rgba(26,26,26,0) 0%, rgba(26,26,26,0.3) 50%, rgba(26,26,26,0.4) 100%);
background: -ms-linear-gradient(top, rgba(26,26,26,0) 0%, rgba(26,26,26,0.3) 50%, rgba(26,26,26,0.4) 100%);
background: linear-gradient(to bottom, rgba(26,26,26,0) 0%, rgba(26,26,26,0.3) 50%, rgba(26,26,26,0.4) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a1a1a', endColorstr='#1a1a1a', GradientType=0 );


}

.bottomSlide{
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.capzoomIn{
	background: rgba(26,26,26,1);
	filter: none;
    opacity: 0;
    filter: alpha(opacity=0);
}

.img-wrapper:hover .capzoomIn{
	opacity: 1;
    filter: alpha(opacity=100);
}

.img-caption.capzoomIn a,
.img-caption.capzoomIn a:hover,
.img-caption.capzoomIn a:focus,
.img-caption.capzoomIn a:active,
.img-caption h5 a,
.img-caption.capzoomIn h5 a:hover,
.img-caption.capzoomIn h5 a:focus,
.img-caption.capzoomIn h5 a:active{
	color:#fff;
}

.img-caption h5{
	margin-bottom:5px;
}

.img-wrapper .content{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
}

.img-wrapper .capzoomIn .content{
	width:100%;
	height:100%;
}

.img-title{
	position:relative;
	display:block;
	width:100%;
	padding:20px 0 0 0;
}

.img-title h5,
.img-title p{
	margin-bottom:10px;
}

.img-title h5 a{
	color:#333;
}

.img-title .rating{
	position:absolute;
	right:0;
	bottom:0;
	font-size:16px;
}

.column-wrapper{
	float:left;
	width:100%;
	border:1px solid #d5d5d5;
}

.img-containt{
	float:left;
	background:#fff;
	width:100%;
	border:1px solid #d5d5d5;
	border-top:none;
	padding:0;
	-webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.02);
	-moz-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.02);
	box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.02);
}

.img-containt.half-column{
	border:none;
	float:left;
	width:50%;
	padding:40px 30px 40px 30px;
	-webkit-box-shadow:none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.img-containt h5{
	margin-bottom:10px;
}

.img-containt h5 a{
	color:#333;
}

.img-containt .post-meta{
	border-bottom:1px solid #ddd;
	padding-bottom:15px;
	margin-bottom:15px;
}

.img-body{
	float:left;
	width:100%;
	padding:20px 25px 0 25px;
}

.img-footer{
	position:relative;
	float:left;
	width:100%;
	background:#f9f9f9;
	border-top:1px solid #d5d5d5;
	padding:20px 25px 20px 25px;
	-webkit-box-shadow:inset 0 2px 0 0 rgba(0, 0, 0, 0.03);
	-moz-box-shadow:inset 0 2px 0 0 rgba(0, 0, 0, 0.03);
	box-shadow:inset 0 2px 0 0 rgba(0, 0, 0, 0.03);
}

.img-footer img{
	width:40px;
	height:40px;
	border-radius:50%;
	float:left;
	margin:0 15px 0 0;
}

.img-footer h6{
	margin-bottom:5px;
}

.img-footer h6 a{
	color:#333;
}

.img-footer .btn{
	position:absolute;
	top:50%;
	right:25px;
	margin-top:-15px;
}

.containt-caption{
	position:absolute;
	width:60%;
	background:rgba(255, 255, 255, 0.96);
	left:20%;
	top:50%;
	padding:0;
	text-align:center;
	margin-top:-25%;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.containt-caption:hover{
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.8);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.8);
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.8);
}

.containt-caption .containt{
	padding:30px 30px 10px 30px;
}

.cap-link{
	display:block;
	padding:20px 20px;
	background:rgba(220, 220, 220, 0.3);
	font-weight:700;
	color:#292929;
	font-size:14px;
	line-height:14px;
}

.cap-link:after{
	font-family: 'FontAwesome';
	content:"\f178";
	font-size:14px;
	margin-left:5px;
}

.cap-link:hover,
.cap-link:active,
.cap-link:focus{
	text-decoration:none;
	color:#fff;
}

.column-content{
	padding:40px 0;
}

.column-content .link-list{
	float:left;
	width:100%;
}

.column-content .link-list li{
	float:left;
	width:50%;
	padding-right:20px;
}

/*
Shopping
=========================== */
.product-wrapper{
	float:left;
	margin-bottom:30px;
}

.product-wrapper.product-list{
	float:left;
	width:100%;
	border-bottom:1px solid #e8e8e8;
	margin-bottom:40px;
	padding:0 0 40px 0;
}

.product-wrapper.product-list .col-md-4{
	padding-left:0;
}

.product-wrapper.product-list .col-md-8{
	padding-right:0;
}

.product-containt{
	position:relative;
	float:left;
	width:100%;
	padding:20px 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.product-wrapper.product-list .product-containt{
	padding:0;
}

.product-wrapper:hover .product-containt{
	background:#f9f9f9;
	padding:20px;
}

.product-wrapper.product-list:hover .product-containt{
	background:none;
	padding:0;
}

.product-containt h6{
	margin:5px 0 0 0;
}

.product-containt h5{
	margin:10px 0 30px 0;
	padding-bottom:20px;
	border-bottom:1px solid #cecece;
}

.product-containt h6 a,
.product-wrapper.product-list .product-containt h5 a{
	color:#2b2b2b;
}

.parallax .product-containt h6 a,
.parallax .product-wrapper.product-list .product-containt h5 a{
	color:#fff;
}


.product-containt .price{
	font-size:18px;
}

.product-wrapper.product-list .product-containt .price{
	font-size:24px;
}

.product-containt .price del{
	margin-right:10px
}

.rating{
	color:#ffc929;
}

.product-containt .rating{
	color:#ffc929;
	position:absolute;
	right:20px;
	bottom:20px;
}

.product-label{
	position:absolute;
	text-align:center;
	color:#fff;
	font-size:18px;
	line-height:18px;
	padding:5px 40px;
	z-index:4;
	top:15px;
	right:-30px;
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Safari */
    transform: rotate(45deg); /* Standard syntax */
}

.product-caption{
	position:absolute;
	background:rgba(255, 255, 255, 0.5);
	width:100%;
	height:100%;
	top:0;
	left:0;
}

.product-caption a{
	position:absolute;
	width:45px;
	height:45px;
	display:block;
	left:20px;
	opacity:0.8;
}

.product-caption a .fa{
	width:45px;
	height:45px;
	background-color:#464646;
	font-size:20px;
	line-height:40px;
}

.product-caption a:hover{
	opacity:1;
}

.product-caption a.add-cart,
.product-caption a.view-detail,
.product-caption a.share{
	bottom:100%;
}

.product-caption .social-share{
	position:absolute;
	display:block;
	left:0;
	bottom:20px;
	width:100%;
	height:45px;
	padding-left:50px;
}

.product-caption .social-share a{
	position:relative;
	display:inline-block;
	margin-right:1px;
}

.filter-attributes{
	display:block;
	padding-bottom:10px;
	margin-bottom:20px;
}

.filter-attributes .form-group{
	padding-right:10px;
	margin-bottom:5px;
}

.product-list{
	float:left;
	width:100%;
	margin:0;
	padding:0;
}

.product-list li{
	float:left;
	width:100%;
	margin:0 0 15px 0;
	padding:0 0 5px 0;
	list-style:none;
	border-bottom:1px solid #d5d5d5;
}

.product-list li h6{
	margin:10px 0 5px 0;
}

.product-list li h6 a{
	color:#2b2b2b;
}

.product-list .price{
	font-size:14px;
}

.product-list .price del{
	margin-right:5px;
}

.grider{
    display: inline-block;
	float:right;
	margin-top:45px;
}

.grider a{
	border:1px solid #c1c1c1;
	color:#787878;
	padding:10px 15px;
	height:46px;
	font-size:14px;
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: -15px;
}

.grider a.active{
	color:#fff;
}

.result{
	float:right;
}

.shop-title{
	display:block;
	position:relative;
	padding-bottom:30px;
	margin-bottom:30px;
	border-bottom:1px solid #cecece;
}

.shop-title .product-label{
	top:0;
	right:0;
}

.shop-title .price{
	font-size:18px;
}

.shop-title .price del{
	margin-right:5px;
}

#start{
	margin-bottom:20px;
}

table.parameter-product{
	width:100%;
	margin-bottom:20px;
}

table.parameter-product tr.active{
	background:#f6f6f6;
}

table.parameter-product tr td{
	padding:10px;
	border:solid 1px #d5d5d5;
}

table.parameter-product tr td strong{
	color:#2b2b2b;
}

.category-product{
    position: relative;
    overflow: hidden;
}

.category-product img{
    -webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.category-product:hover img{
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.option-product{
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 25px 30px;
    letter-spacing: 3px;
    background-color: #fff;
    background: rgba(255,255,255,0.7);
}

.option-product .title{
    margin-bottom: 0;
    text-transform: uppercase;
}

.option-product .title small{
    color: #292929;
}

.option-product p{
    margin-bottom: 0;
}

.category-product > a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

/*
Shopping cart
=========================== */
.styled-table {
  clear:both;
  width: 100%;
  background:#fff;
}

.parallax .styled-table {
	color:#787878;
}

.styled-table thead th,
.styled-table thead td {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

.styled-table thead th {
  font-weight: 600;
}

.styled-table thead td {
  font-weight: 400;
}

.styled-table tbody th,
.styled-table tbody td {
  border-bottom: 1px solid #d5d5d5;
}

.styled-table thead th,
.styled-table tbody th,
.styled-table thead td,
.styled-table tbody td {
  text-align: left;
  padding: 15px;
  vertical-align: top;
}

.styled-table tbody td {
  padding-top: 30px;
  padding-bottom: 30px;
}

.styled-table thead th:first-child,
.styled-table tbody th:first-child,
.styled-table thead td:first-child,
.styled-table tbody td:first-child {
  padding-left: 15px;
}

.styled-table thead th:last-child,
.styled-table tbody th:last-child,
.styled-table thead td:last-child,
.styled-table tbody td:last-child {
  padding-right: 15px;
}

.styled-table thead th.text-left,
.styled-table tbody th.text-left,
.styled-table thead td.text-left,
.styled-table tbody td.text-left {
  text-align: left;
}

.styled-table thead th.text-center,
.styled-table tbody th.text-center,
.styled-table thead td.text-center,
.styled-table tbody td.text-center {
  text-align: center;
}

.styled-table thead th.text-right,
.styled-table tbody th.text-right,
.styled-table thead td.text-right,
.styled-table tbody td.text-right {
  text-align: right;
}

.styled-table .alignleft{
	margin:0 20px 0 0;
}

.item-title{
	line-height:32px;
	margin:0;
}

.item-title a{
	color:#333;
}

.item-title span{
	font-weight:400;
}

.shoppingcart-action{
	display:block;
	padding-top:30px;
}

.cart-total{
	display:block;
	border: 1px solid #d5d5d5;
	padding:30px;
	box-shadow:6px 6px 0 0 rgba(43, 43, 43, 0.05);
	-webkit-box-shadow: 6px 6px 0 0 rgba(43, 43, 43, 0.05);
	-moz-box-shadow: 6px 6px 0 0 rgba(43, 43, 43, 0.05);
}

.subtotal{
	font-size:16px;
	line-height:16px;
	margin-bottom:10px;
}

.cart-total .form-inline{
	border-bottom:1px solid #d5d5d5;
	padding-bottom:35px;
	margin-bottom:30px;
}

.grand-total{
	font-size:24px;
	line-height:32px;
	text-transform:uppercase;
	margin-bottom:10px;
}

.order-detail{
	float:left;
	width:100%;
	border: 1px solid #d5d5d5;
	padding:30px 30px 0 30px;
	box-shadow:6px 6px 0 0 rgba(43, 43, 43, 0.05);
	-webkit-box-shadow: 6px 6px 0 0 rgba(43, 43, 43, 0.05);
	-moz-box-shadow: 6px 6px 0 0 rgba(43, 43, 43, 0.05);
}

.basket-shopping,
.basket-shopping label{
	font-size:18px;
	line-height:18px;
	font-weight:700;
	color:#fff;
	padding:10px 15px 10px 15px;
	margin:0;
	border-radius:2px;
}

.basket-shopping span{
	font-weight:400;
}

.shop-categorie-wrapp{
	float:left;
	width:100%;
	margin-bottom:60px;
}

.shop-categorie{
	position:relative;
	float:left;
	width:50%;
	overflow:hidden;
}

/*
Clients
=========================== */
.client-list{
	float:left;
	width:100%;
	margin:0;
	padding:0;
}

.client-list li{
	position:relative;
	float:left;
	width:100%;
	list-style:none;
	margin:0;
	padding:0;
	text-align:center;
	border-top:1px solid #ddd;
}

.client-list li:first-child{
	border:none;
}

.client-list ul li{
	float:left;
	width:25%;
	margin:0;
	padding:30px;
	border:none;
	border-left:1px solid #ddd;
}

.client-list ul li:first-child{
	border-left:none;
}

.client-logo{
	display:block;
    position: relative;
}

.client-default{
    position: absolute;
	display:inline-block;
	z-index:2;
    max-width:100%;
}

.client-hover{
	display:inline-block;
	max-width:100%;
}

.client-box{
	position:relative;
	display:block;
	padding:0;
	margin:0;
	text-align:center;
}

/*
Blog
=========================== */
article.post{
	float:left;
	width:100%;
	margin-bottom:40px;
}

article.post.row{
	margin-bottom:40px;
}

.grid-item article.post{
	margin-bottom:10px;
}

article.post h5 a{
	color:#333;
}

article.post.row h5{
	margin-bottom:10px;
}

article.post .media-wrapper{
	display:block;
	position:relative;
	margin-bottom:30px;
}

.recent-widget{
	display:block;
	margin:0 0 30px 0;
	padding:0;
}

.recent-widget .post{
	position:relative;
	display:block;
	min-height:92px;
	border-bottom:1px solid #dcdcdc;
	margin:0 0 20px 0;
	padding:0 0 20px 80px;
}

.recent-widget.recent-article .post{
	border:none;
	padding:0;
	margin:0;
}

.recent-widget.recent-article .img-responsive{
	margin:0 0 20px 0;
}

.recent-widget h6{
	margin-bottom:0;
}

.recent-widget.recent-article h6{
	margin:10px 0 15px 0;
}

.recent-widget h6 a{
	color:#333333;
	line-height:24px;
}

.post-thumbnail{
	position:absolute;
	left:0;
	top:5px;
	width:80px;
	height:80px;
}

.post-thumbnail img{
	border-radius:2px;
}

.recent-widget .price{
	font-size:18px;
	margin-top:10px;
}

.recent-widget .rating{
	color:#ffc929;
	font-size:12px;
	float:none;
	margin-bottom:10px;
}

ul.post-meta{
	display:block;
	margin:0 0 5px 0;
	padding:0;
	font-size:12px;
}

article.post ul.post-meta{
	float:left;
	width:100%;
	border-top:1px solid #dcdcdc;
	border-bottom:1px solid #dcdcdc;
	padding:15px 0;
	margin:10px 0 0 0;
	font-size:13px;
}

article.post.row ul.post-meta{
	border:none;
	padding:0;
	margin:0 0 10px 0
}

ul.post-meta li{
	display:inline-block;
	list-style:none;
	margin:0;
	padding:0 5px 0 0;
}

ul.post-meta > li + li:before {
	padding:0 10px 0 0;
	color: #aeaeae;
	content: "|";
}

ul.post-meta li a{
	color:#aeaeae;
}

.recent-post{
	margin:0;
	padding:0;
}

.recent-post li{
	border-bottom:1px dashed transparent;
	border-color:#e8e8e8;
	list-style:none;
	margin:0 0 20px 0;
	padding:0;
}

.recent-post li a{
	color:#616161;
}

.post-data{
	float:left;
	font-size:18px;
	line-height:28px;
	margin:0 15px 0 0;
	padding-left:2px;
	color:#adadad;
}

.full-recent{
	float:left;
	width:100%;
	position:relative;
	display:block;
	border-top:1px solid #dcdcdc;
}

.full-recent .item{
	margin:0;
}

.recent-box{
	border-right:1px solid #dcdcdc;
	padding:30px;
	margin:0;
}

.recent-widget.recent-box img{
	margin-bottom:15px;
	border-radius:2px;
}

.recent-widget.recent-box .img-wrapper img{
	margin-bottom:0;
	border-radius:0;
}

.recent-widget.recent-box .post{
	padding:0;
	margin:0;
	border:none;
}

/*
Aside
=========================== */
aside{
	float:left;
	width:100%;
}

aside .widget{
	float:left;
	width:100%;
	margin-bottom:30px;
}

aside .widget .widget-head{
	position:relative;
	padding-bottom:20px;
	margin-bottom:30px;
}

aside .widget .widget-head:before{
	display:block;
	content: "";
	position:absolute;
	width:100%;
	height:1px;
	background:#cecece;
	bottom:0;
	left:0;
}

aside .widget .widget-head:after{
	display:block;
	content: "";
	position:absolute;
	width:60px;
	height:3px;
	bottom:-1px;
	left:0;
}

aside .widget .recent-widget{
	margin:0 0 -15px 0;
}

ul.cat{
	float:left;
	width:100%;
	margin:-5px 0 -5px 0;
	padding:0;
}

ul.cat li{
	position:relative;
	float:left;
	width:100%;
	list-style:none;
	margin:0 0 5px 0;
	padding:0 0 0 15px;
}

ul.cat li:before{
	display:block;
	content: "\f101";
	font-family:'FontAwesome';
	position:absolute;
	top:0;
	left:0;
}

ul.cat li a{
	color:#787878;
}

ul.photostream{
	float:left;
	width:100%;
	margin:0;
	padding:0;
}

ul.photostream li{
	float:left;
	background:#333;
	width:60px;
	height:60px;
	margin:0 5px 5px 0;
	list-style:none;
	padding:0;
}

ul.photostream li a:hover{
	display:block;
	opacity:0.5;
}

.tag{
	display:inline-block;
	border:1px solid #d5d5d5;
	border-radius:2px;
	color: #616161;
	text-align:center;
	padding:5px 15px;
	margin-bottom:5px;
}

.tag:hover,
.tag:focus,
.tag:active{
	text-decoration:none;
	color:#fff;
}

ul.ads{
	display:block;
	margin:0;
	padding:0;
	text-align:center;
}

ul.ads li{
	display:inline-block;
	list-style:none;
	margin:0;
	padding:0 8px;
}

/*
Comments
=========================== */
.media-object{
	border:1px solid #dcdcdc;
	padding:5px;
	margin-right:15px;
	border-radius:2px;
}

.media-heading a{
	color:#0b0b0b;
}

.media-body{
	border:1px solid #dcdcdc;
	padding:30px 30px 0 30px;
	border-radius:2px;
}

.media,
.media .media{
	margin-bottom:30px;
}

.media .media .media-object{
	background:none;
	border-radius:2px;
}

.media .media .media-body{
	background:none;
}

.reply{
	display:block;
	width:100%;
	text-align:right;
	margin-bottom:30px;
}

.rate:hover,
.rate:focus,
.rate:active{
	text-decoration:none;
}

/*
Contact
=========================== */
.contact-detail{
	position:relative;
	background:#fff;
	display:block;
	border: 1px solid #d5d5d5;
	border-radius:2px;
	padding:30px 40px 0 40px;
}

.contact-detail ul{
	position:relative;
	z-index:3;
}

.contact-sparator{
	background:url(../img/contact-bg.png) no-repeat top right;
	position:absolute;
	display:block;
	width:100%;
	height:68px;
	top:-1px;
	right:-1px;
}

#contact .form-control{
	background:none;
	border:none;
	border-radius:0;
	padding:0;
	color: #fff;
	border-bottom:1px solid #fff;
	-webkit-box-shadow:none;
	-moz-box-shadow: none;
	box-shadow: none;
}

#contact .jcf-select .jcf-select-text {
	color: #fff;
	padding:0;
}

#contact .form-control::-moz-placeholder {
	color: #fff;
	opacity: 1;
}

#contact .form-control:-ms-input-placeholder {
	color: #fff;
}

#contact .form-control::-webkit-input-placeholder {
	color: #fff;
}

.status-progress{
	display: inline-block;
	margin-left: 55px;
	position: relative;
	font-size: 24px;
	top: -20px;
}

/*
Restaurant
=========================== */
.reservation{
	float:left;
	width:100%;
	background:#1a1a1a;
	position:relative;
	display:block;
	padding:0;
}

.reservation h1,
.reservation h2,
.reservation h3,
.reservation h4,
.reservation h5,
.reservation h6{
	color:#fff;
	text-transform:uppercase;
	margin-bottom:10px;
}

.reservation .title{
	font-family: 'Allura', cursive;
	text-transform:none;
	font-size:42px;
	border-bottom:1px dashed #3b3b3b;
	padding-bottom:20px;
	margin-bottom:25px;
}

.open-time{
	position:relative;
	float:left;
	width:100%;
	background:#222222;
	padding:20px;
}

.open-time:before,
.open-time:after{
	content:"";
	position:absolute;
	width:100%;
	height:20px;
	background:#222222;
	display:block;
	top:-20px;
	left:0;
	z-index:4;
}

.open-time:after{
	top:100%;
}

.open-content{
	border:1px dashed #3b3b3b;
	padding:30px 30px 15px 30px;
	color:#adadad;
	text-align:center;
}

.open-content:before,
.open-content:after{
	border-bottom: 20px solid #121212;
	border-left: 20px solid transparent;
	position:absolute;	
	content: "";
	display:block;
	width: 0;
	height: 0;
	left:-20px;
	top:-20px;
	z-index:2;
}

.open-content:after{
	border-bottom: 20px solid #121212;
	border-left:none;
	border-right: 20px solid transparent;
	left:100%;
	top:-20px;
}

.reservation-contact{
	font-weight:300;
	border-top:1px dashed #3b3b3b;
	padding-top:20px;
	margin:0;
}

.reservation-form{
	padding:40px 10px;
}

.reservation-form .form-control,
.reservation-form .jcf-select{
	background:none;
	border:none;
	border-radius:0;
	padding:0;
	border-bottom:1px solid #3b3b3b;
	-webkit-box-shadow:none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.reservation-form .jcf-select .jcf-select-text {
	color: #7c7c7c;
	padding:0;
}

.reservation-form .form-control::-moz-placeholder {
	color: #7c7c7c;
	opacity: 1;
}

.reservation-form .form-control:-ms-input-placeholder {
	color: #7c7c7c;
}

.reservation-form .form-control::-webkit-input-placeholder {
	color: #7c7c7c;
}

.reservation-form .input-group{
	border-bottom:1px solid #3b3b3b;
	margin:0;
}

.reservation-form .input-group .form-control{
	border-bottom:none;
	padding-left:15px;
}

.reservation-form .input-group .input-group-btn .btn-number{
	background:none;
	padding:0;
}

.reservation-form .input-group .input-group-btn .btn-number:hover,
.reservation-form .input-group .input-group-btn .btn-number:focus,
.reservation-form .input-group .input-group-btn .btn-number:active{
	outline:none;
}

/*
Testimoni
=========================== */
.testimoni-wrapp{
	display:block;
	position:relative;
	margin-bottom:30px;
}

.parallax .testimoni-wrapp{
	color:#787878;
}

.testimoni-wrapp.pull-right{
	float:none !important;
}

.testimoni-wrapp.centered{
	text-align:center;
}

.testimoni-contain{
	display:block;
	position:relative;
	background:#fff;
	border:1px solid #dcdcdc;
	padding:30px;
	margin-bottom:30px;
	border-radius:5px;
	-webkit-box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 11px -1px rgba(0,0,0,0.1);
}

.testimoni-contain:before{
	background:url(../img/testimoni-arrow.png) no-repeat 0 0;
	display:block;
	content: "";
	position:absolute;
	width:41px;
	height:29px;
	bottom:-29px;
	left:0;
}

.testimoni-wrapp.pull-right .testimoni-contain:before{
	background:url(../img/testimoni-arrow-right.png) no-repeat 0 0;
	left:100%;
	margin-left:-41px;
}

.testimoni-wrapp.centered .testimoni-contain:before{
	background:url(../img/testimoni-arrow-center.png) no-repeat 0 0;
	width:58px;
	height:29px;
	left:50%;
	margin-left:-29px;
}

.testimo-author{
	display:block;
	position:relative;
	padding-left:80px;
}

.testimoni-wrapp.pull-right .testimo-author{
	text-align:right;
	padding-right:80px;
}

.testimoni-wrapp.centered .testimo-author{
	text-align:center;
	padding-left:0;
}


.testimo-avatar{
	position:absolute;
	width:65px;
	height:65px;
	border-radius:50%;
	top:0;
	left:0;
}

.testimoni-wrapp.pull-right .testimo-avatar{
	left:100%;
	margin-left:-65px;
}

.testimoni-wrapp.centered .testimo-avatar{
	position:relative;
	display:inline-block;
}

.testimo-author span{
	color:#333333;
	font-weight:700;
	display:block;
	font-size:16px;
	padding:5px 0;
}

.testimonial{
	position:relative;
	display:block;
	width:100%;
	padding-left:145px;
}

.testimonial img{
	position:absolute;
	width:120px;
	height:120px;
	top:0;
	left:0;
}

.testimonial .author{
	padding-left:60px;
}

.testimonial blockquote{
	background:url(../img/quote-left.png) no-repeat 25px 0;
	padding-left:60px;
}

.testimonial:before{
	content:"";
	position:absolute;
	width:1px;
	height:100%;
	background:#fff;
	top:0;
	left:145px;
}

/*
Parallax
=========================== */
.parallax{
	position:relative;
	padding:80px 0;
	margin: 0 auto;
	overflow-y: visible;
	width: 100%;
	z-index:3;
	color:#fff;
    -moz-background-size:cover;
    -webkit-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
	background: 50% 0 no-repeat fixed;
}

.parallax .title-head,
.cd-section .title-head{
	color:#fff;
}

.parallax .overlay{
    background-color: #1e1e1e;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    filter: alpha(opacity=40);
    z-index: 1;
}

.parallax .content-parallax{
    position: relative;
    z-index: 2;
}

.parallax .content-parallax h1,
.parallax .content-parallax h2,
.parallax .content-parallax h3,
.parallax .content-parallax h4,
.parallax .content-parallax h5,
.parallax .content-parallax h6{
    color: #fff;
}

.headliner{
	color:#fff;
	font-size:18px;
	line-height:36px;
	display:block;
	margin-bottom:40px;
}

/*
Counter
=========================== */
.counter-item{
    display: block;
    position: relative;
    text-align: center;
    border: double 3px rgba(255, 255, 255, 0.6);
	border-radius:2px;
	padding:30px 20px;
	margin-bottom:30px;
}

.counter-item .fa{
    font-size: 46px;
	margin-right:10px;
}

.counter-item .count-value{
	display:block;
    font-size: 54px;
    margin:0;
    font-weight: 700;
	margin-bottom:10px;
}

.counter-item p{
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
	font-size:24px;
}

/*
Precess
=========================== */
.precess-column{
	text-align:center;
}

.icons-wrapp{
    display: block;
    width: 140px;
    height: 140px;
    margin: 0 auto 20px;
    position: relative;
    background:#363636;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

.icons-wrapp.icons-primary{
	color:#fff;
	border-radius:50%;
	overflow:hidden;
	padding-top:30px;
}

.icons-wrapp.icons-primary i{
	font-size:32px;
	line-height:34px;
}

.icons-wrapp.icons-primary h6{
	color:#fff;
	line-height:20px;
}

.icons-wrapp:before{
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 120px;
    height: 120px;
	border:8px solid;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transition: background 0.5s; /* Safari */
    -webkit-transition-timing-function: linear; /* Safari */
    transition: background 0.5s;
    transition-timing-function: linear;
}

.icons-wrapp .precess-icons{
    position: absolute;
    width: 90px;
    height: 90px;
    top: 50%;
    left: 50%;
    text-align: center;
    margin-top: -45px;
	margin-left: -45px;
	padding-top:20px;
    font-size: 52px;
	line-height:45px;
	color:#fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -webkit-transition: border 0.5s; /* Safari */
    -webkit-transition-timing-function: linear; /* Safari */
    transition: border 0.5s;
    transition-timing-function: linear;
	border:5px solid #363636;
}

.icons-wrapp .count{
    display: block;
    position: absolute;
    width: 35px;
    height: 35px;
    top:100%;
    left: 50%;
    margin-top: -45px;
    margin-left: 25px;
    text-align: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 14px;
	line-height:23px;
	font-weight:700;
    color: #fff;
	border:5px solid #363636;
}

.precess-column.hover .precess-icons{
	border:5px solid #363636;
	color:#fff;
}

.precess-column.hover .count{
	color:#fff;
	background:none !important;
}

.precess-column.hover .icons-primary{
	background:none;
}

.process-icon{
    position: relative;
    margin-bottom: 30px;
}

.process-icon > .fa{
    font-size: 36px;
    margin-bottom: 15px;
}

.process-icon > span{
    display: block;
}

.process-icon > p:last-child{
    margin-bottom: 0;
}

.process-line{
	float:left;
	width:100%;
	margin:0 0 30px 0;
	padding:0 32px;
}

.process-line li{
	float:left;
	list-style:none;
	width:33.333333%;
	margin:0;
	padding:0;
}

.process-head{
	position:relative;
	float:left;
	width:100%;
	border-bottom:1px solid #ddd;
	padding:20px 0 20px 45px;
}

.process-head h5{
	margin:0 0 10px 0;
}

.process-line li.hedingbot .process-head{
	border-bottom:none;
	border-top:1px solid #ddd;
}

.process-line li.hedingbot .process-head h5{
	margin:10px 0 0 0;
}

.process-head:before{
    content: "";
	position:absolute;
	width:1px;
	height:20px;
	background:#ddd;
	bottom:0;
	left:0;
}

.process-line li.hedingbot .process-head:before{
	top:0;
}

.process-head .ph-icon{
	position:absolute;
	border:1px solid #ddd;
	width:64px;
	height:64px;
	border-radius:50%;
	text-align:center;
	font-size:28px;
	line-height:38px;
	padding-top:10px;
	overflow:hidden;
	bottom:20px;
	left:-32px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.process-line li:hover .process-head .ph-icon{
	color:#fff;
}

.process-line li.hedingbot .process-head .ph-icon{
	top:20px;
}

.process-content{
	float:left;
	width:100%;
	padding:20px 40px 20px 20px;
}

.process-content p{
	margin:0;
}

.process-line > li + li .process-content{
	border-left:1px dashed #ddd;	
}

/*
Google Map
=========================== */
.map-wrapper{
	display:block;
	width:100%;
	margin:0;
	padding:0;
	position:relative;
	overflow:hidden;
}

.maps{
    height: 450px;
    width: 100%;
}

/*
Pricing
=========================== */
ul.list-pricing{
    padding: 0;
    margin: 35px -1px -7px -1px;
    list-style: none;
    display: inline-block;
    width: 100%;
}

ul.list-pricing > li{
    float: left;
    display: block;
    padding: 0;
    margin: 0 -1px;
}

.pricing-item {
	position: relative;
	display: block;
    width: 100%;
    text-align: center;
    border: solid 1px #dfdfdf;
    margin: 0 -0.5px;
    padding: 25px 30px;
	cursor: default;
    background-color: #fff;
    -moz-box-shadow: 0px 1px 2px #dfdfdf;
    -webkit-box-shadow: 0px 1px 2px #dfdfdf;
    -o-box-shadow: 0px 1px 2px #dfdfdf;
    box-shadow: 0px 1px 2px #dfdfdf;
    z-index: 1;
    margin-bottom: 30px;
	border-radius:2px;
}

ul.list-pricing > li > .pricing-item.active{
    z-index: 2;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: -25px;
}

.pricing-item .icon .fa{
    display: table-cell;
    width: 75px;
    height: 75px;
    text-align: center;
    vertical-align: middle;
    margin: auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    background-color: #333;
}

.pricing-item .pricing-title {
	margin: 15px 0 0;
}

.pricing-item .pricing-price {
	font-weight: bold;
	margin: 25px 0;
	overflow: hidden;
    font-size: 30px;
    color: #3f3f3f;
}

.pricing-item .pricing-currency {
	vertical-align: super;
    font-size: 14px;
}

.pricing-item .pricing-period{
    font-size: 14px;
    color: #6f6f6f;
}

.pricing-item .pricing-anim {
	display: inline-block;
	position: relative;
}

.pricing-item:hover .pricing-anim {
	-webkit-animation: moveUp 0.4s forwards;
	animation: moveUp 0.4s forwards;
	-webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.pricing-item:hover .pricing-anim-2 {
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
}

@-webkit-keyframes moveUp {
	50% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	51% { opacity: 0; -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	52% { opacity: 1; -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

@keyframes moveUp {
	50% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	51% { opacity: 0; -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	52% { opacity: 1; -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

.pricing-item .pricing-sentence {
	font-weight: bold;
    margin-bottom: 0;
}

.pricing-item .pricing-feature-list {
	margin: 0 0 25px 0;
	list-style: none;
	text-align: center;
}

/*
Progress Bar
=========================== */
.wrap-progress{
	overflow: hidden;
}

.progress{
	height: 25px;
	position: relative;
	overflow: visible;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	background-color: #f0f0f0;
	display: block;
	margin-bottom: 15px;
}

.progress .progress-bar{
	padding-top: 2px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
}

.progress .value-progress:after{
	content: "%";
}

.progress.type1{
	margin-top: 30px;
	height: 15px;
	margin-bottom: 35px;
}

.progress.type1 .data-progress,
.progress.type1 .value-progress{
	position: absolute;
	top: -25px;
	color: #6f6f6f;
}

.progress.type1 .data-progress{
	left: 0;
}

.progress.type1 .value-progress{
	right: 0;
}

.progress.type2{
	height: 15px;
	margin-top: 30px;
	margin-bottom: 35px;
}

.progress.type2 .data-progress,
.progress.type2 .value-progress{
	position: absolute;
	top: -25px;
}

.progress.type2 .data-progress{
	color: #666;
	left: 0;
}

.progress.type2 .value-progress{
	background-color: #333;
	padding: 1px 7px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
	margin-left: -40px;
	left: 100%;
	top: -26px;
}

.wrap-progress3{
	padding-right: 45px;
	overflow: hidden;
}

.progress.type3{
	height: 10px;
	margin-top: 20px;
}

.progress.type3 .circle{
	content: "";
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	top: -4px;
	border: solid 5px;
	background-color: #f0f0f0;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	margin-left: -20px;
}

.progress.type3 .data-progress,
.progress.type3 .value-progress{
	position: absolute;
}

.progress.type3 .data-progress{
	color: #666;
	left: 0;
	top: -25px;
}

.progress.type3 .value-progress{
	padding: 1px 7px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-o-border-radius: 2px;
	border-radius: 2px;
	right: -45px;
	top: -9px;
	color: #6f6f6f;
}

/*
Search
=========================== */
.fieldset-search{
	position:relative;
	width:100%;
	display:block;
	height:80px;
	overflow:hidden;
}


.searching{
	display:block;
	width:100%;
	height:80px;
	border-radius:5px;
	background:rgba(255, 255, 255, 0.95);
	border:1px solid #fff;
	font-size:16px;
	padding:10px 25px;
	color: #868686;
	font-style:normal;
}

.searching::-moz-placeholder {
	color: #adadad;
	opacity: 1;
}

.searching:-ms-input-placeholder {
	color: #adadad;
}

.searching::-webkit-input-placeholder {
	color: #adadad;
}

.searching:focus{
	border-color:#fff !important;
	background:#fff;
}

.searching-button{
	position:absolute;
	background:none;
	border:none;
	font-size:72px;
	top:0;
	right:0;
	color:#e2e1df;
}

/*
Under construction
=========================== */
.underconstruction-wrapp{
	background:url(../img/underconstruction-bg.jpg) no-repeat top center;
	padding:140px 0;
}

.underconstruction-wrapp h1{
	text-transform:uppercase;
	margin-top:100px;
}

.underconstruction-alt1{
	background:url(../img/underconstruction-alt1-bg.jpg) no-repeat top center fixed;
	position:fixed;
	width:100%;
	height:100%;
	text-align:center;
	padding:100px 0;
}

.countdown-wrapp{
	background:none;
    text-align: center;
}

.underconstruction-alt1 .countdown-wrapp{
	margin-top:20px;
}

.main-example {
	display:block;
	text-align:center;
    color: #333;
}

.main-example .countdown-container {
	height: 110px;
}

.main-example .time {
    display: inline-block;
    text-align: center;
    position: relative;
    height: 73px;
    width: 80px;
    margin: 0px 5px;

    -webkit-perspective: 500px;
    -moz-perspective: 500px;
    -ms-perspective: 500px;
    -o-perspective: 500px;
    perspective: 500px;

    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);

    -webkit-box-shadow: 0px 2px 5px #222;      
    -moz-box-shadow: 0px 2px 5px #222;      
    -ms-box-shadow: 0px 2px 5px #222;      
    -o-box-shadow: 0px 2px 5px #222;      
    box-shadow: 0px 2px 5px #222;      
}

.main-example .count {
    background: #202020;
    color: #f8f8f8;
    display: block;
    font-size: 48px;
    line-height: 29px;
    font-weight: bold;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;  

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-transform-style: flat;
    -moz-transform-style: flat;
    -ms-transform-style: flat;
    -o-transform-style: flat;
    transform-style: flat;
}

.main-example .count.top {
    background: #383838;
    border-radius: 3px 3px 0px 0px;
    -moz-border-radius: 3px 3px 0px 0px;
    -webkit-border-radius: 3px 3px 0px 0px;
    -o-border-radius: 3px 3px 0px 0px;
    height: 50%;
    padding-top: 28%;
    top: 0px;    
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;      
}

.main-example .count.bottom {
    background-color: #1E1E1E;
    border: solid 1px #1E1E1e;    
    border-top: 1px solid #ccc;
    border-radius: 0px 0px 3px 3px;
    -moz-border-radius: 0px 0px 3px 3px;
    -webkit-border-radius: 0px 0px 3px 3px;
    -o-border-radius: 0px 0px 3px 3px;
    line-height: 0;
    height: 50%;
    top: 50%;
    z-index: 1;    
    -webkit-transform-origin: 50% 0;
    -moz-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    -o-transform-origin: 50% 0;
    transform-origin: 50% 0;  

    -webkit-box-shadow: inset 0px -1px 1px 0px #444;
    -moz-box-shadow: inset 0px -1px 1px 0px #444;
    box-shadow: inset 0px -1px 1px 0px #444;     
}

.time .midle-line{
    width: 80%;
    background: none;
    height: 7px;
    border-left: solid 3px #666;
    border-right: solid 3px #666;
    position: absolute;
    left: 10%;
    top: 50%;
    z-index: 3;
    margin-top: -3px;
}

.time .botttom-line{
    position: absolute;
    width: 94%;
    height: 3px;
    left: 3%;
    bottom: -3px;
    background-color: #444;
    border: solid 1px #1E1E1e;
    border-radius: 0px 0px 3px 3px;
      -moz-border-radius: 0px 0px 3px 3px;
      -webkit-border-radius: 0px 0px 3px 3px;
      -o-border-radius: 0px 0px 3px 3px;
    z-index: 2;
    -webkit-box-shadow: 0px 2px 5px #222;      
    -moz-box-shadow: 0px 2px 5px #222;      
    -ms-box-shadow: 0px 2px 5px #222;      
    -o-box-shadow: 0px 2px 5px #222;      
    box-shadow: 0px 2px 5px #222; 
}


.time .botttom-line2{
    position: absolute;
    width: 92%;
    height: 5px;
    left: 4%;
    bottom: -5px;
    background-color: #444;
    border: solid 1px #1E1E1e;
    border-top: none;
    border-radius: 0px 0px 3px 3px;
      -moz-border-radius: 0px 0px 3px 3px;
      -webkit-border-radius: 0px 0px 3px 3px;
      -o-border-radius: 0px 0px 3px 3px;
    z-index: 1;
    -webkit-box-shadow: 0px 2px 5px #222;      
    -moz-box-shadow: 0px 2px 5px #222;      
    -ms-box-shadow: 0px 2px 5px #222;      
    -o-box-shadow: 0px 2px 5px #222;      
    box-shadow: 0px 2px 5px #222; 
}

.main-example .count.next {
}

.main-example .label {
    font-size: 18px;
    font-weight: bold;    
    margin-top: 0px;
    padding: 0px;
    display: block;
    position: absolute;
    top: 95px;
    width: 100%;
	color: #333;
    text-transform: uppercase;
}

.countdown-wrapp.white-bg .main-example .label {
	color:#2b2b2b;
}

/* Animation start */
.main-example .count.curr.top {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    z-index: 3;
}

.main-example .count.next.bottom {
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transform: rotateX(90deg);
    z-index: 2;
}

/* Animation end */
.main-example .flip .count.curr.top {
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;

    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
}

.main-example .flip .count.next.bottom {
    -webkit-transition: all 250ms ease-in-out 250ms;
    -moz-transition: all 250ms ease-in-out 250ms;
    -ms-transition: all 250ms ease-in-out 250ms;
    -o-transition: all 250ms ease-in-out 250ms;
    transition: all 250ms ease-in-out 250ms;

    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

/*
Maintenance
=========================== */
.maintenance{
	background:#f3f3f3;
	padding:80px 0;
}

.maintenance-logo{
	position:absolute;
	z-index:2;
	left:30%;
	bottom:0;
	margin:0 200px 100px 0
}

.circle-wrapp{
	position:relative;
	display:block;
}

.circle-content{
	position:relative;
	width:400px;
	height:400px;
	border-radius:50%;
	background:#fff;
	display:inline-block;
	margin-right:190px;
	margin-bottom:-40px;
	padding:100px 80px;
	font-size:14px;
	line-height:28px;
}

.circle-content h4{
	line-height:40px;
}

.circle-content p{
	margin-bottom:15px;
}

.circle-content:after{
	content:"";
	position:absolute;
	width:39px;
	height:41px;
	background:url(../img/maintenance-arrow.png) no-repeat 0 0;
	bottom:-19px;
	left:50%;
	margin-left:60px;
}

.circle-intro{
	position:absolute;
	width:200px;
	height:200px;
	background:#333;
	border-radius:50%;
	text-align:center;
	padding:70px 0;
	font-size:48px;
	line-height:48px;
	font-weight:700;
	color:#fff;
	top:20px;
	left:-140px;
	z-index:2;
}

.circle-intro p{
	margin:0;
}

.maintenance-img{
	display:inline-block;
	margin-left:90px;
}

/*
Error 404
=========================== */
.error-page{
	position:fixed;
	background:#f3f3f3;
	display:block;
	padding:100px 0;
	text-align:center;
	width:100%;
	height:100%;
	left:0;
	bottom:0;
}

.error-page .circle-content{
	margin:0;
}

.error-page .circle-content:after{
	display:none;
}

/*
Login and register
=========================== */
.logreg-wrapp{
	background:#f3f3f3 url(../img/logreg-wrapp-bg.jpg) no-repeat center center fixed;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	padding:155px 0;
}

.register-wrapp,
.login-wrapp{
	background:#fff;
	float:left;
	width:100%;
	border-radius:4px;
	overflow:hidden;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.login-wrapp{
	margin-top:30px;
}

.img-column{
	float:left;
	width:33.333333%;
}

.logreg-contain{
	float:left;
	width:66.666666%;
	border-left:none;
	padding:0;
}

.login-wrapp .logreg-contain{
	width:100%;
}

.logreg-contain .custom-tabs .nav-tabs{
	width:100%;
}

.logreg-contain .custom-tabs .nav-tabs li{
	margin:0;
	width:50%;
	text-align:center;
}

.logreg-contain .custom-tabs .nav-tabs li a{
	margin:0;
	padding:20px;
	border-radius:0;
}

.logreg-contain .custom-tabs .nav-tabs li.active a{
	background:none !important;
	border-color:transparent !important;
	color:#333;
}

.logreg-contain .custom-tabs .tab-content{
	padding:30px 50px;
}

.register-link{
	position:relative;
	text-align:center;
	margin-top:-20px;
	z-index:5;
}

.register-link .signup-btn,
.register-link .signup-btn:hover,
.register-link .signup-btn:focus,
.register-link .signup-btn:active{
	position:relative;
	display:inline-block;
	border-radius:0 0 2px 2px;
	padding:25px 55px;
	font-size:32px;
	line-height:32px;
	text-transform:uppercase;
	font-weight:700;
	font-style:italic;
	letter-spacing:2px;
	color:#fff;
	text-decoration:none;
}

.register-link .signup-btn:before,
.register-link .signup-btn:after{
	position:absolute;	
	content: "";
	display:block;
	width: 0;
	height: 0;
	left:-20px;
	top:0;
	z-index:1;
}

.register-link .signup-btn:before{
	border-bottom: 20px solid;
	border-left: 20px solid transparent;
}

.register-link .signup-btn:after{
	border-bottom: 20px solid;
	border-right: 20px solid transparent;
	left:100%;
}

/*
Custom search
=========================== */
.custom-search-wrapp{
	position:relative;
	float:left;
	display:block;
	width:100%;
	border:5px solid #333;
	border-radius:2px;
	margin-bottom:30px;
	background:#fff;
	z-index:5;
}

.custom-offset-top{
	margin-top:-120px;
}

.custom-search-head{
	float:left;
	width:100%;
	padding:30px 30px 30px 30px;
	text-align:center;
	color:#fff;
	background:#333;
}

.custom-search-head h1, .custom-search-head h2, .custom-search-head h3, .custom-search-head h4, .custom-search-head h5, .custom-search-head h6{
	margin:0;
	color:#fff;
}

.custom-search-wrapp .pull-left,
.custom-search-wrapp .pull-right{
	padding:30px;
}

.custom-search-wrapp .pull-right{
	border-left:5px solid #333;
}

/*
Member area
=========================== */
.member-header{
	position:relative;
	width:100%;
	height:auto;padding:0;
	margin:0;
	display:block;
}

.navwrapp{
	position:relative;
	float:left;
	width:100%;
	margin-bottom:40px;
}

.photo-profile{
	position:absolute;
	overflow:hidden;
	width:220px;
	height:220px;
	padding:10px;
	background:#fff;
	border:1px solid #d4d4d4;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	bottom:100%;
	left:30px;
	z-index:2;
	margin-bottom:20px;
}

.photo-caption,
.photo-caption:hover,
.photo-caption:focus,
.photo-caption:active{
	position:absolute;
	background:rgba(255, 255, 255, 0.8);
	width:100%;
	height:100%;
	top:0;
	left:0;
	text-align:center;
	color:#bbbbbb;
	font-size:56px;
	line-height:82px;
	padding:60px;
}

.img-pload{
	border:none;
}

.membernav{
	display:block;
	width:100%;
	margin:0;
	padding:0;
	clear:both;
	background:#fff;
	border:1px solid #d4d4d4;
	border-top:none;
	border-radius:2px;
	text-align:center;
	-webkit-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.05);
}

.membernav li{
	display:inline-block;
	list-style:none;
	margin:0 -4px 0 0;
	padding:0;
}

.membernav li a{
	display:block;
	color:#333;
	font-weight:700;
	border-top:2px solid transparent;
	border-right:1px solid #d4d4d4;
	padding:20px 30px;
}

.membernav li a:hover,
.membernav li a:focus,
.membernav li a:active{
	text-decoration:none;
}

.membernav li:first-child a{
	border-left:1px solid #d4d4d4;
}

.member-identity .widget{
	padding:30px;
	border:1px solid #d4d4d4;
	border-radius:2px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.profile{
	margin:0 0 20px 0;
	padding:0;
}


.profile li{
	list-style:none;
	margin:0 0 15px 0;
	padding:0 0 10px 0;
	font-size:14px;
	border-bottom:1px dashed #d4d4d4;
}

.profile li label{
	padding-right:5px;
	color:#333;
}

/*
Footer
=========================== */
footer{
	float:left;
	width:100%;
	background:#222222;
	padding:70px 0 0 0;
	color:#bababa;
}

footer.gray-footer{
	background:#f5f5f5;
	color:#787878;
}

footer.nobackground{
	background:none;
	border-top:1px solid #eeeeee;
	padding:20px 0 0 0;
}

footer.gray-footer h1,
footer.gray-footer h2,
footer.gray-footer h3,
footer.gray-footer h4,
footer.gray-footer h5,
footer.gray-footer h6{
	color:#333;
}

footer .widget{
	position:relative;
	float:left;
	width:100%;
	margin-bottom:30px;
}

footer.nobackground .widget{
	margin-bottom:0;
}

footer .recent-post li{
	border-color:#454545;
}

footer.gray-footer .recent-post li{
	border-color:#bcbcbc;
}

footer .recent-post li a{
	color:#bababa;
}

footer.gray-footer .recent-post li a,
footer.gray-footer .link-list li a{
	color:#787878;
}

footer.gray-footer .icon-square{
	background:#cacaca;
	color:#929292;
}

footer.gray-footer .icon-square:hover{
	color:#fff;
}

.subfooter{
	float:left;
	width:100%;
    background: #1a1a1a;
    color: #898989;
	text-align:center;
	margin-top:20px;
	padding-top:30px;
}

footer.gray-footer .subfooter{
	background:#e4e4e4;
}

.fixed-copyright{
	color:#333333;
	font-size:14px;
	line-height:28px;
	letter-spacing:2px;
	text-transform:uppercase;
	margin:40px 0 0 0;
}

/*
Twitter Feed
=========================== */
.tweet ul{
    margin: 0;
    padding: 0;
}

.tweet ul li{
	background:url(../img/twitter.png) no-repeat 0 10px;
    list-style: none;
    margin:0;
    padding: 0 0 0 40px;
    min-height: 35px;
}

.twt-item img{
    display: none;
}

/* Media responsive
=========================== */
.video-container,.map-container,.embed-container{
position:relative;
padding-bottom:51%;
padding-top:30px;
height:0;
overflow:hidden;
border:none;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.video-container iframe,
.map-container iframe,
.map-container object,
.map-container embed,
.video-container object,  
.video-container embed{
	 position:absolute;
	 top:0;
	 left:0;
	 width:100%;
	 height:100%;
 }
 
iframe {
	border:none;
}
/*
Fontawesome
=========================== */
.fa-2x {
	font-size: 2em;
}

.fa-3x {
	font-size: 3em;
}

.fa-4x {
	font-size: 4em;
}

.fa-5x {
	font-size: 5em;
}

.fa-default{
	color:#464646;
}

.icon-circle,
.icon-square{
	background-color:#464646;
	color:#fff;
	border-radius:50%;
	width:34px;
	height:34px;
	border:2px solid transparent;
	text-align:center;
	line-height:28px;
	overflow:hidden;
	cursor:pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

footer .icon-circle,
footer .icon-square{
	color:#c0c0c0;
}

.icon-square{
	border-radius:2px;
}

.icon-circle.icon-bordered,
.icon-square.icon-bordered{
	background:none;
	color:#464646;
	border-color:#464646;
}

.icon-circle.icon-bordered:hover,
.icon-square.icon-bordered:hover,
.icon-circle.icon-bordered.fa-primary:hover,
.icon-square.icon-bordered.fa-primary:hover,
.icon-circle.icon-bordered.fa-blue:hover,
.icon-square.icon-bordered.fa-blue:hover,
.icon-circle.icon-bordered.fa-purple:hover,
.icon-square.icon-bordered.fa-purple:hover,
.icon-circle.icon-bordered.fa-pink:hover,
.icon-square.icon-bordered.fa-pink:hover,
.icon-circle.icon-bordered.fa-yellow:hover,
.icon-square.icon-bordered.fa-yellow:hover,
.icon-circle.icon-bordered.fa-green:hover,
.icon-square.icon-bordered.fa-green:hover,
.icon-circle.icon-bordered.fa-red:hover,
.icon-square.icon-bordered.fa-red:hover{
	background:none;
	color:#fff !important;
}

.fa-2x.icon-circle,
.fa-2x.icon-square{
	width:56px;
	height:56px;
	font-size: 24px;	
	line-height:54px;
}

.fa-3x.icon-circle,
.fa-3x.icon-square{
	width:76px;
	height:76px;	
	font-size: 36px;
	line-height:74px;
}

.fa-4x.icon-circle,
.fa-4x.icon-square{
	width:96px;
	height:96px;	
	font-size: 46px;
	line-height:94px;
}

.fa-5x.icon-circle,
.fa-5x.icon-square{
	width:120px;
	height:120px;	
	font-size: 56px;
	line-height:118px;
}

.icon-circle.fa-primary,
.icon-circle.fa-blue,
.icon-circle.fa-purple,
.icon-circle.fa-pink,
.icon-circle.fa-yellow,
.icon-circle.fa-green,
.icon-circle.fa-red,
.icon-square.fa-primary,
.icon-square.fa-blue,
.icon-square.fa-purple,
.icon-square.fa-pink,
.icon-square.fa-yellow,
.icon-square.fa-green,
.icon-square.fa-red{
	color:#fff;
}

.icon-circle.icon-bordered.fa-primary,
.icon-circle.icon-bordered.fa-blue,
.icon-circle.icon-bordered.fa-purple,
.icon-circle.icon-bordered.fa-pink,
.icon-circle.icon-bordered.fa-yellow,
.icon-circle.icon-bordered.fa-green,
.icon-circle.icon-bordered.fa-red,
.icon-square.icon-bordered.fa-primary,
.icon-square.icon-bordered.fa-blue,
.icon-square.icon-bordered.fa-purple,
.icon-square.icon-bordered.fa-pink,
.icon-square.icon-bordered.fa-yellow,
.icon-square.icon-bordered.fa-green,
.icon-square.icon-bordered.fa-red,
.icon-circle.icon-bordered.fa-primary:hover,
.icon-circle.icon-bordered.fa-primary:active,
.icon-circle.icon-bordered.fa-primary:focus{
	background:none !important;
}

/*
Icon and text
=========================== */
@-webkit-keyframes icon-up{
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  25%,
  75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}

@keyframes icon-up{
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  25%,
  75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}

.col-icon{
	float:left;
	width:100%;
	position:relative;
	margin-bottom:10px;
}

.col-icon.centered{
	text-align:center;
}

.col-icon.pull-right{
	text-align:right;
}

.col-icon.pull-left .fa{
	float:left;
	margin:5px 20px 10px 0;
}

.col-icon.pull-right .fa{
	float:right;
	margin:5px 0 10px 20px;	
}

.col-icon.centered i{
	float:none;
	margin:5px 0 15px 0;
}

.icon-wrapp{
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
	background:#fff;
	border:1px solid #d5d5d5;
	border-radius:2px;
	-webkit-box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
	box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);	
}

ul.icon-boxline{
	float:left;
	width:100%;
	margin:0;
	padding:0;
}

ul.icon-boxline li{
	float:left;
	width:20%;
	list-style:none;
	margin:0;
	padding:0;
	text-align:center;
}

ul.icon-boxline li .containt{
	padding:35px 25px 20px 25px;
	background: rgba(0,0,0,0.05);
	background: -moz-linear-gradient(left, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.03) 53%, rgba(0,0,0,0.05) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.05)), color-stop(53%, rgba(0,0,0,0.03)), color-stop(100%, rgba(0,0,0,0.05)));
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.03) 53%, rgba(0,0,0,0.05) 100%);
	background: -o-linear-gradient(left, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.03) 53%, rgba(0,0,0,0.05) 100%);
	background: -ms-linear-gradient(left, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.03) 53%, rgba(0,0,0,0.05) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.03) 53%, rgba(0,0,0,0.05) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

ul.icon-boxline li .containt .fa{
	margin-bottom:10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

ul.icon-boxline li .containt:hover .fa{
  -webkit-animation-name: icon-up;
  animation-name: icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

ul.icon-boxline li .containt p{
	margin-bottom:10px;
}

ul.icon-boxline li.bg1{
	background: #fafafa;
}

ul.icon-boxline li.bg2{
	background: #f0f0f0;
}

ul.icon-boxline li.bg3{
	background: #e7e7e7;
}

ul.icon-boxline li.bg4{
	background: #dfdfdf;
}

ul.icon-boxline li.bg5{
	background: #d8d8d8;
}

.absolute-left,
.absolute-right{
	position:relative;
	padding-left:80px;
}

.absolute-left .fa{
	position:absolute;
	top:0;
	left:0;
	margin-top:10px;
	padding:0;
}

.absolute-right{
	padding-left:0;
	padding-right:80px;
	text-align:right;
}

.absolute-right .fa{
	position:absolute;
	top:0;
	right:0;
	margin-top:10px;
	padding:0;
}

.icon-center{
	text-align:center;
	display:block;
}

.icon-center .fa{
	margin-bottom:10px;
}

.icon-box{
	position:relative;
	background:#fff url(../img/icon-box.png) repeat-x bottom left;
	float:left;
	width:100%;
	margin:0 0 30px 0;
	padding:40px 0 30px 0;
	border:1px solid #ddd;
	border-radius:2px;
}

.icon-box:after{
	background:url(../img/shadow.png) no-repeat top center;
	position:absolute;
	content:"";
	width:100%;
	height:29px;
	bottom:-30px;
	left:0;
}

.icon-box li{
	position:relative;
	float:left;
	width:33.333333%;
	list-style:none;
	margin:0;
	padding:0 40px 0 100px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.icon-box > li + li{
	border-left:1px solid #ddd;
}


.icon-box li .fa{
	position:absolute;
	font-size:36px;
	line-height:36px;
	color:#333;
	margin-bottom:20px;
	top:0;
	left:40px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.icon-box li:hover .fa{
  -webkit-animation-name: icon-up;
  animation-name: icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.icon-box-contain{
	position:relative;
	background:#fdfdfd;
	border:1px solid #fafafa;
	border-radius:2px;
	padding:30px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.icon-box-contain .fa{
	margin-bottom:10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.icon-box-contain:hover .fa{
  -webkit-animation-name: icon-up;
  animation-name: icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

.icon-box.pull-left .icon-box-contain{
	padding-left:90px;
}

.icon-box.pull-right .icon-box-contain{
	padding-right:90px;
}

.icon-box .icon-box-contain p{
	margin-bottom:10px;
}

.icon-box .icon-box-contain h5{
	margin-bottom:15px;
}

.icon-box .icon-box-contain .fa{
	color:#333333;
	display:inline-block;
	margin-bottom:10px;
}

.icon-box.pull-left .icon-box-contain .fa{
	position:absolute;
	left:30px;
	top:30px;
}

.icon-box.pull-right .icon-box-contain .fa{
	position:absolute;
	right:30px;
	top:30px;
}

.icon-linner{
	float:left;
	width:100%;
	margin:0 0 30px 0;
	padding:0;
}

.icon-linner li{
	position:relative;
	float:left;
	width:100%;
	list-style:none;
	margin:0;
	padding:0;
	text-align:center;
	border-top:1px solid #ddd;
}

.icon-linner li:first-child{
	border:none;
}

.icon-linner ul li{
	float:left;
	width:25%;
	margin:0;
	padding:30px;
	border:none;
	border-left:1px solid #ddd;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.icon-linner ul li:first-child{
	border-left:none;
}

.icon-linner ul li .fa{
	color:#333;
	display:inline-block;
	margin-bottom:10px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.icon-linner ul li:hover .fa{
  -webkit-animation-name: icon-up;
  animation-name: icon-up;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/*
Pricing
=========================== */
ul.list-pricing{
    padding: 0;
    margin: 35px -1px -7px -1px;
    list-style: none;
    display: inline-block;
    width: 100%;
}

ul.list-pricing > li{
    float: left;
    display: block;
    padding: 0;
    margin: 0 -1px;
}

.pricing-item {
	position: relative;
	display: block;
    width: 100%;
    text-align: center;
    border: solid 1px #dfdfdf;
    margin: 0 -0.5px;
    padding: 25px 30px;
	cursor: default;
    background-color: #fff;
    -moz-box-shadow: 0px 1px 2px #dfdfdf;
    -webkit-box-shadow: 0px 1px 2px #dfdfdf;
    -o-box-shadow: 0px 1px 2px #dfdfdf;
    box-shadow: 0px 1px 2px #dfdfdf;
    z-index: 1;
    margin-bottom: 20px;
}

ul.list-pricing > li > .pricing-item.active{
    z-index: 2;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: -25px;
}

.pricing-item .icon .fa{
    display: table-cell;
    width: 75px;
    height: 75px;
    text-align: center;
    vertical-align: middle;
    margin: auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    background-color: #333;
}

.pricing-item .pricing-title {
	margin: 15px 0 0;
}

.pricing-item .pricing-price {
	font-weight: bold;
	margin: 25px 0;
	overflow: hidden;
    font-size: 30px;
    color: #3f3f3f;
}

.pricing-item .pricing-currency {
	vertical-align: super;
    font-size: 14px;
}

.pricing-item .pricing-period{
    font-size: 14px;
    color: #6f6f6f;
}

.pricing-item .pricing-anim {
	display: inline-block;
	position: relative;
}

.pricing-item:hover .pricing-anim {
	-webkit-animation: moveUp 0.4s forwards;
	animation: moveUp 0.4s forwards;
	-webkit-animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	animation-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.pricing-item:hover .pricing-anim-2 {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}

@-webkit-keyframes moveUp {
	50% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	51% { opacity: 0; -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	52% { opacity: 1; -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

@keyframes moveUp {
	50% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	51% { opacity: 0; -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	52% { opacity: 1; -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

.pricing-item .pricing-sentence {
	font-weight: bold;
    margin-bottom: 0;
}

.pricing-item .pricing-feature-list {
	margin: 0 0 25px 0;
	list-style: none;
	text-align: center;
}

/*
Real Estate
=========================== */
.wrap-estate{
	display:block;
	width: 100%;
	height:100%;
    position: relative;
	background:#222222;
	color:#bababa;
	padding:40px 0 10px 0;
}

.wrap-estate label{
	display:block;
	color:#fff;
	font-size:18px;
	font-weight:400;
	margin-bottom:10px;
}

.wrap-estate h5{
	position:absolute;
	background:#222222;
	color:#fff;
	padding:10px 20px 10px 65px;
	top:0;
	margin-top:-75px;
	margin-bottom:0;
	border-radius:3px 3px 0 0;
}

.wrap-estate h5:after,
.wrap-estate h5:before{
	position:absolute;	
	content: "";
	display:block;
	width: 0;
	height: 0;
	bottom:0;
	z-index:1;
}

.wrap-estate h5:before{
	border-top: 9px solid #181818;
	border-left: 9px solid transparent;
	left:-9px;
}

.wrap-estate h5:after{
	border-top: 9px solid #181818;
	border-right: 9px solid transparent;
	right:-9px;
}

.wrap-estate h5 i{
	position:absolute;
	width:50px;
	height:100%;
	top:0;
	left:0;
	text-align:center;
	font-size:24px;
	line-height:44px;
	border-radius:3px 0 0 0;
}

.wrap-estate .form-control,
.wrap-estate .jcf-select{
	background:none;
}

/*
Preloading
=========================== */
div.loading-invisible{
    display:none;
}

#loading{
	background:rgba(255, 255, 255, 0.98);
	height: 100%;
	width: 100%;
	position: fixed;
	z-index:9999999;
	margin-top: 0px;
	top: 0px;
	color:#787878;
	text-align:center;
	font-size:18px;
	letter-spacing:4px;
}

.loading-center{
	width: 100%;
	height: 100%;
	position: relative;
}

.loading-center-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 200px;
	width: 200px;
	margin-top: -100px;
	margin-left: -100px;
	-ms-transform: rotate(-135deg); 
   	-webkit-transform: rotate(-135deg); 
    transform: rotate(-135deg);

}

.object{
	-moz-border-radius: 50% 50% 50% 50%;
	-webkit-border-radius: 50% 50% 50% 50%;
	border-radius: 50% 50% 50% 50%;
	position: absolute;
	border-top: 5px solid #333;
	border-bottom: 5px solid transparent;
	border-left:  5px solid #ddd;
	border-right: 5px solid transparent;
	-webkit-animation: animate 2s infinite;
	animation: animate 2s infinite;	
}

#object_one{
	left: 75px;
	top: 75px;
	width: 50px;
	height: 50px;
}
							
#object_two{
	left: 65px;
	top: 65px;
	width: 70px;
	height: 70px;
	-webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

#object_three{
	left: 55px;
	top: 55px;
	width: 90px;
	height: 90px;
	-webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
	
#object_four{
	left: 45px;
	top: 45px;
	width: 110px;
	height: 110px;
	-webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}	

@-webkit-keyframes animate {
	50% {

		-ms-transform: rotate(360deg) scale(0.8); 
		-webkit-transform: rotate(360deg) scale(0.8); 
		transform: rotate(360deg) scale(0.8); 
	  }
}

@keyframes animate {
	50% {

		-ms-transform: rotate(360deg) scale(0.8); 
		-webkit-transform: rotate(360deg) scale(0.8); 
		transform: rotate(360deg) scale(0.8); 
	  }
}

.loading-center p{
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: 90px;
	margin-left: -79px;	
}

/*
Back to top
=========================== */
.toTop{
	display:none;
	text-decoration:none;
	z-index:600;
	position:fixed;
	bottom:20px;
	right:20px;
	width:45px;
	height:45px;
	border:none;
	text-align:center;
	background-color:rgba(33, 33, 33, 0.6);
	border-radius:2px;
	color:#fff;
	font-size:16px;
    -webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
    padding-top: 8px;
}

.toTop:hover,
.toTop:focus{
    color: #fff;
}

/*
Clear spacer
=========================== */
.margin-clear{
	margin:0 !important;
}

.padding-clear{
	padding:0 !important;
}

.margintop-clear{
	margin-top:0 !important;
}

.paddingtop-clear{
	padding-top:0 !important;
}

.marginbot-clear{
	margin-bottom:0 !important;
}

.paddingbot-clear{
	padding-bottom:0 !important;
}

.marginleft-clear{
	margin-left:0 !important;
}

.paddingleft-clear{
	padding-left:0 !important;
}

.marginright-clear{
	margin-right:0 !important;
}

.paddingright-clear{
	padding-right:0 !important;
}

.padding-top10{
	padding-top:10px !important;
}

.padding-top20{
	padding-top:20px !important;
}

.padding-top30{
	padding-top:30px !important;
}

.padding-top40{
	padding-top:40px !important;
}

.padding-top50{
	padding-top:50px !important;
}

.padding-top60{
	padding-top:60px !important;
}

.padding-top70{
	padding-top:70px !important;
}

.padding-bot5{
	padding-bottom:5px !important;
}

.padding-bot10{
	padding-bottom:10px !important;
}

.padding-bot15{
	padding-bottom:15px !important;
}

.padding-bot20{
	padding-bottom:20px !important;
}

.padding-bot30{
	padding-bottom:30px !important;
}

.padding-bot35{
	padding-bottom:30px !important;
}

.padding-bot40{
	padding-bottom:40px !important;
}

.padding-bot45{
	padding-bottom:45px !important;
}

.padding-bot50{
	padding-bottom:50px !important;
}

.padding-bot60{
	padding-bottom:60px !important;
}

.padding-bot70{
	padding-bottom:70px !important;
}

.margin-mintop10{
	margin-top:-10px !important;
}

.margin-mintop20{
	margin-top:-20px !important;
}

.margin-mintop30{
	margin-top:-30px !important;
}

.margin-mintop60{
	margin-top:-60px !important;
}

.margin-mintop110{
	margin-top:-110px !important;
	z-index:4;
}

.margin-minbot10{
	margin-bottom:-10px !important;
}

.margin-minbot20{
	margin-bottom:-20px !important;
}

.margin-minbot30{
	margin-bottom:-30px !important;
}

.margin-minbot60{
	margin-bottom:-60px !important;
}

/*
Custom left right spacer
=========================== */
.margin-left10{
	margin-left:10px !important;
}

.margin-right10{
	margin-right:10px !important;
}

.margin-left20{
	margin-left:20px !important;
}

.margin-right20{
	margin-right:20px !important;
}

/*
Custom top spacer
=========================== */
.margintop5{
	margin-top:5px !important;
}

.margintop10{
	margin-top:10px !important;
}

.margintop15{
	margin-top:15px !important;
}

.margintop20{
	margin-top:20px !important;
}

.margintop25{
	margin-top:25px !important;
}

.margintop30{
	margin-top:30px !important;
}

.margintop35{
	margin-top:35px !important;
}

.margintop40{
	margin-top:40px !important;
}

.margintop45{
	margin-top:45px !important;
}

.margintop50{
	margin-top:50px !important;
}

.margintop55{
	margin-top:55px !important;
}

.margintop60{
	margin-top:60px !important;
}

.margintop70{
	margin-top:70px !important;
}

.margintop80{
	margin-top:80px !important;
}

/*
Custom bottom spacer
=========================== */
.marginbot5{
	margin-bottom:5px !important;
}

.marginbot10{
	margin-bottom:10px !important;
}

.marginbot15{
	margin-bottom:15px !important;
}

.marginbot20{
	margin-bottom:20px !important;
}

.marginbot25{
	margin-bottom:25px !important;
}

.marginbot30{
	margin-bottom:30px !important;
}

.marginbot35{
	margin-bottom:35px !important;
}

.marginbot40{
	margin-bottom:40px !important;
}

.marginbot45{
	margin-bottom:45px !important;
}


.marginbot50{
	margin-bottom:50px !important;
}

.marginbot55{
	margin-bottom:55px !important;
}

.marginbot60{
	margin-bottom:60px !important;
}

.marginbot70{
	margin-bottom:70px !important;
}

.marginbot80{
	margin-bottom:80px !important;
}

/*
Responsive
=========================== */
@media (width:1024px){
    .img-footer .btn{
        position: relative;
        display: inline-block;
        float: none;
        left: 50px;
        margin-top: 15px;
    }
}

@media (max-width:1024px){
    .responsive-full .half-column{
        width: 100%;
        display: block;
        float: none !important;
    }
    
    .custom-offset-top{
        margin-top:-50px;
    }
    
    .parallax.home-slide{
        padding-top: 0;
    }
    
    .indent.bootsnav{
        margin-top: 0px !important;
    }
    
    .parallax.home-slide .bootsnav .attr-nav > ul > li > a,
    .indent.bootsnav .attr-nav > ul > li > a{
        padding-left: 10px !important;
        padding-right: 10px !important;
        background: transparent !important;
    }
    
    .underconstruction-wrapp{
        padding-top: 60px;
    }
    
    .underconstruction-alt1{
        padding-top: 100px;
    }
}

@media (max-width:920px){
	nav.navbar.navbar-default.white:before{
		display:none;
	}
    .onepage-half-column{
        display: block;
        float: none;
        width: 100%;
        position: relative;
    }
    
    .half-column-wrapp .half-column{
        padding: 60px 30px 40px;
    }
    
    .responsive-full .half-column{
        padding: 0;
    }
    
    .icon-box.margin-mintop110{
        margin-top: 0 !important;
    }
    
    .photography-title{
        font-size:38px;
        line-height:38px;
        padding:20px 30px 20px 30px;
        margin-left: 130px;
    }

    .photography-title h1{
        font-size:110px;
        line-height:110px;
    }
    
    .custom-offset-top{
        margin-top:0px;
        margin-bottom: 80px;
    }
    
    .custom-offset-top .pull-right,
    .custom-offset-top .pull-left{
        float: none;
        display: block;
        width: 100%;
        border: none;
    }
    
    .custom-offset-top .pull-right{
        border-top: solid 5px #333;
    }
    
    .cd-section{
        height: auto !important;
    }
    
    .cd-section .container{
        height: auto !important;
        padding-top: 80px;
        padding-bottom: 80px;
    }
    
    .scrolltop{
        margin-bottom: 0;
    }
    
    .scrolltop h2{
        font-size:48px;
        line-height:70px;
    }

    .filter-items.filter-wrapp li a:hover,
    .filter-items.filter-wrapp li a:focus,
    .filter-items.filter-wrapp li a{
        padding: 15px 25px;
        border-top:1px solid #d5d5d5;
        border-bottom:1px solid #d5d5d5;
        border-left:1px solid #d5d5d5;
    }
    
    .parallax.home-slide nav.navbar{
        margin-left: -9px;
    }
    
    .membernav li a{
        padding:10px 13px;
    }
    
    .grid-item .column-wrapper .half-column{
        float: none;
        display: block;
        width: 100%;
    }
    
    .maintenance .circle-wrapp{
        margin-left: 90px;
    }
    
    .maintenance .circle-intro{
        left: -100px;
    }
    
    .underconstruction-alt1{
        padding-top: 100px;
    }
    
}

@media (max-width:767px){
    .video-wrapper .play-icon{
        -ms-transform: scale(0.5,0.5); /* IE 9 */
        -webkit-transform: scale(0.5,0.5); /* Safari */
        transform: scale(0.5,0.5);
    }
    
    .tab-pane .big-title-tab{
        font-size: 62px;
        line-height: 74px;
    }
    
    .wrap-menu-page .container{
        display: none;
        padding: 0;
    }
    
    ul.nav.fixed-nav-tabs{
        display: block;
        border: none;
        width: 100%;
    }
    
    ul.nav.fixed-nav-tabs > li{
        float:none;
        display: block;
        width: 100%;
        text-align: center;
    }
    
    ul.nav.fixed-nav-tabs > li > a:hover,
    ul.nav.fixed-nav-tabs > li > a{
        border: none;
    }
    
    .toggle-mobile{
        display: table;
        margin: auto;
    }
    
    .intro-wrapp ul.intro-icon-line{
        display: block;
        margin-top: 40px;
    }
    
    .intro-wrapp ul.intro-icon-line li:last-child,
    .intro-wrapp ul.intro-icon-line li:first-child,
    .intro-wrapp ul.intro-icon-line li{
        float: none;
        display: block;
        width: 100%;
        padding: 0 !important;
    }
    
    .intro-wrapp .col-icon{
        display: block;
        position: relative;
        padding-bottom: 20px;
    }
    
    .intro-wrapp ul.intro-icon-line > li + li:before{
        display: none;
    }
    
    .half-column-wrapp .half-column{
        width: 100%;
        padding: 10px 15px 40px !important;
    }
    
    .responsive-full .half-column{
        padding: 0 15px !important;
    }
    
    .responsive-full .flexslider{
        margin: 0 -15px 30px -15px;
    }
    
    .half-column-wrapp .video-wrapper{
        margin: -10px 0 0 !important;
    }
    
    ul.icon-boxline li{
        float: none;
        display: block;
        width: 100%;
    }
    
    ul.icon-box li{
        float: none;
        display: block;
        width: 100%;
    }
    
    ul.list-pricing > li > .pricing-item.active{
        margin-top: 0;
    }
    
    .process-line li{
        float: none;
        width: 100%;
        display: block;
        position: relative;
    }
    
    .process-line li .process-content,
    .process-line li .process-head{
        position: relative;
        display: block;
        width: 100%;
    }
    
    .process-line li .process-content{
        border-left: none !important;
    }
    
    .process-line li.hedingbot .process-head{
        margin-bottom: 30px;
    }
    
    .icon-linner ul li{
        float: none;
        display: block;
        width: 100%;
        border: none !important;
        border-bottom: solid 1px #dfdfdf !important;
    }
    
    .vertical-link.top-link,
    .vertical-link.left-link,
    .vertical-link.right-link,
    .vertical-link{
        position: relative;
        display: block;
        width: 100%;
        top: 0;
        left: 0;
        padding: 0 !important;
        height: auto !important;
        margin: 0 !important;
        -ms-transform: rotate(0deg); /* IE 9 */
        -webkit-transform: rotate(0deg); /* Safari */
        transform: rotate(0deg); /* Standard syntax */
    }
    
    .absolute-title{
        font-size:56px;
        line-height:62px;
    }
    
    .photography-title{
        font-size:28px;
        line-height:28px;
        padding:20px 30px 20px 30px;
        margin-left: 45px;
    }

    .photography-title h1{
        font-size:90px;
        line-height:90px;
    }
    
    .open-time{
        margin-top: 20px;
        margin-bottom: 80px;
    }
    
    .open-content:before,
    .open-content:after{
        display: none;
    }
    
    .large-header .main-title{
        padding-top: 100px;
        font-size: 18px;
    }
    
    .large-header .main-title h1{
        font-size: 38px;
        line-height: 42px;
    }
    
    .filter-items li a:hover,
    .filter-items li a:focus,
    .filter-items li a{
        padding: 10px 20px;
    }
    
    .parallax.home-slide nav.navbar{
        margin-left: 0px;
    }
    
    .client-list li:first-child,
    .client-list ul li{
	   width:100%;
        display: block;
        float: none;
        border: none;
        border-bottom:1px solid #ddd;
    }
    
    .client-list li:last-child{
        border-bottom: none;
    }
    
    .membernav li{
        float: none;
        display: block;
        width: 100%;
    }
    
    .membernav li a{
        border-right: none;
        border-top: solid 1px #d4d4d4;
        padding: 15px 20px;
    }
    
    .photo-profile{
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
    }
    
    .photo-caption,
    .photo-caption:hover,
    .photo-caption:focus,
    .photo-caption:active{
        position:absolute;
        background:rgba(255, 255, 255, 0.8);
        width:100%;
        height:100%;
        top:0;
        left:0;
        text-align:center;
        color:#bbbbbb;
        font-size:26px;
        line-height:52px;
        padding:25px;
    }
    
    .maintenance, .error-page{
        display: block;
        overflow-y: hidden;
    }
    
    .circle-content{
        width: 300px;
        height: 300px;
        padding: 25px 30px;
        background: transparent;
    }
    
    .circle-content:after{
        display: none;
    }
    
    .circle-content .circle-intro{
        position: relative;
        top: 0;
        left: 0; 
        display: table;
        margin: 0 auto 10px;
        padding: 0;
        background: transparent;
        color: #3a3a3a;
        width: auto;
        height: auto;
    }
    
    .maintenance-img{
        width: 200px;
        display: table;
        margin: 25px auto 0;
    }
    
    .maintenance .circle-wrapp{
        margin-left: 0px;
    }
    
    .maintenance .circle-intro{
        left: 0px;
    }
    
    .underconstruction-alt1{
        padding-top: 100px;
    }
    
    .underconstruction-alt1 .countdown-wrapp h2{
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 0;
    }
    
    .main-example {
        width: 100%;
    }
    
    .main-example .countdown-container {
        height: 50px;
    }
    
    .main-example .time {
        height: 60px;
        width: 58px;
        margin-bottom: -30px;
    }
    
    .main-example .time .label{
        margin-top: -15px;
    }
    
    .main-example .count {
        font-size: 36px;
        line-height: 29px;
    }
    
    .filter-attributes .form-group input[type='checkbox']{
        margin-left: 0px;
    }
    
    .product-containt .price{
        margin-top: 30px;
    }
    
    .styled-table.mobile-responsive thead {
		display: none;
	}
	.styled-table.mobile-responsive tbody {
		width: 100%;
	}
	.styled-table.mobile-responsive tbody tr{
		overflow: hidden;
		height: auto;
		width: 100%;
		border: 1px solid #e3e3e3;
	}
	.styled-table.mobile-responsive tbody tr td{
		display: block;
		text-align: left;
		padding-left: calc(50% + 10px);
		position: relative;
		overflow: hidden;
		height: auto;
		border-bottom: 1px solid #f0f0f0;
	}
	.styled-table.mobile-responsive tbody tr td:first-child{
		padding: 10px;
		padding-left: calc(50% + 10px);
	}
	.styled-table.mobile-responsive tbody tr td.text-right{
		text-align: left;
	}
	.styled-table.mobile-responsive tbody tr td:before {
		content: attr(data-title);
		display: block;
		font-weight: bold;
		position: absolute;
		left: 0px;
		top: 0px;
		padding: 10px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 50%;
		height: 100%;
		background-color: #fdfdfd;
	}
    
    .shoppingcart-action .col-md-4{
        text-align: left;
        margin-bottom: 15px;
    }
}

@media (min-width:480px) and (max-width:640px){
    .large-header .main-title{
        padding-top: 30px;
    }
}

@media (max-width:480px){
    .team-row{
        float: none;
        width: 100%;
        display: block;
    }
    
    .register-wrapp .img-column{
        display: none;
    }
    
    .register-wrapp .logreg-contain{
        width: 100%;
    }
}

@media (max-width:360px){
    .top-wrapp .top-share{
        margin-bottom: 10px;
    }
    
    .top-wrapp .top-link{
        display: none;
    }
    
    .custom-tabs ul.nav > li > a{
        padding: 10px 15px !important;
    }
    
    .underconstruction-wrapp{
        padding-top: 50px;
    }
    
    .underconstruction-wrapp h1{
        font-size: 32px;
        line-height: 44px;
    }
    
    .underconstruction-wrapp .countdown-wrapp{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .underconstruction-alt1{
        padding-top: 100px;
    }
}

@media (max-width:320px){
    .large-header .main-title{
        padding-top: 50px;
    }
    
    .underconstruction-alt1{
        padding-top: 100px;
    }
}

/*
Demo
=========================== */
.demo-panel{
	background:#fdfdfd;
	width:360px;
	display:none;
	height:100%;
	left:0;
	padding:40px 0; 
	top:0;
	position:fixed;
	_position:absolute;
	z-index:9999;
	-webkit-box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.14);
	-moz-box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.14);
	box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.14);
}

a.openpanel{ 
	background-image:url(../img/openpanel-demo.png); 
	background-repeat:no-repeat;
	background-position:0 0;
	padding:110px 0;
	width:52px;
	height:255px;
	border-left:none;
	border-radius:0 2px 2px 0;
	color:#333;
	display:block;
	left:0;
	text-align:center;
	font-size:18px;
	top:165px;
	z-index:99999; 
	position:fixed;
	_position:absolute; 
	border-left:none;
	text-decoration:none;
}
	
a.active.openpanel{ 
	border-left:none;
	_position:absolute; 
	left:360px;
}

a.openpanel:hover{
	outline:none;
	color:#333;
}

.demo-col{
	float:left;
	width:100%;
	padding:0 30px;
	margin-bottom:20px;
}

.demo-col h6{
	margin-bottom:15px;
}

.stylechanger{
	float:left;
	width:100%;
	margin:0;
	padding:0;
}

.stylechanger li{
	float:left;
	width:25%;
	list-style:none;
	margin:0 0 15px 0;
	padding:0 10px 0 0;
	text-align:center;
}

.stylechanger li a{
	display:block;
	width:100%;
	height:50px;
	margin-bottom:5px;
	border-radius:2px;
	border:5px solid #fcfcfc;
}

.stylechanger li a:hover,
.stylechanger li a:focus,
.stylechanger li a:active{
	border:5px solid #e4e8e9;
}

#color1{ background-color:#ef662f; }
#color2{ background-color:#efa02f; }
#color3{ background-color:#db9754; }
#color4{ background-color:#e04545; }
#color5{ background-color:#bd4c32; }
#color6{ background-color:#aa4e64; }
#color7{ background-color:#cc6699; }
#color8{ background-color:#9761a1; }
#color9{ background-color:#3399cc; }
#color10{ background-color:#1ec1ea; }
#color11{ background-color:#6ca92a; }
#color12{ background-color:#4ab184; }

.demo-header .logo{
	display:inline-block;
	margin-bottom:20px;
}

.demo-header h1{
	font-size:54px;
	line-height:68px;
}

.demo-header{
	font-size:24px;
	line-height:38px;
	font-weight:300;
	text-transform:uppercase;
}

.demo-image{
	border:1px solid #ddd;
	border-radius:2px;
	padding:10px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.grid-item:hover .demo-image{
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}