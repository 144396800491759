/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flexslider-thumbnails, .flexslider-photo a:active,
.flex-container a:focus,
.flexslider a:focus,
.flexslider-thumbnails, .flexslider-photo a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;} 

/* FlexSlider Necessary Styles
*********************************/ 
.flexslider, .flexslider-thumbnails, .flexslider-photo {margin: 0; padding: 0;}
.flexslider .slides > li, .flexslider-thumbnails, .flexslider-photo .slides > li  {display: none; -webkit-backface-visibility: hidden;margin: 0 0 -5px 0; padding: 0; list-style:none;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img, .flexslider-thumbnails, .flexslider-photo .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider, .flexslider-thumbnails, .flexslider-photo {margin:0;zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slidesm .flexslider-thumbnails, .flexslider-photo .slides {zoom: 1;}

.carousel li {margin-right: 5px}


/* Direction Nav */
.flex-direction-nav a, .flex-direction-nav a:focus, .flex-direction-nav a:active {text-decoration:none;overflow: hidden; margin: -30px 0 0;padding:10px 15px;border-radius:2px; display: block; background:rgba(43, 43, 43, 0.4);background-repeat:no-repeat;background-position:0 0;background-color:none;border:1px solid transparent; position: absolute; top: 50%; cursor: pointer; opacity: 0; -webkit-transition: all .3s ease;text-align:center;color:#fff;font-size:24px;line-height:24px;}
.flex-direction-nav .flex-next {right: -40px; display: block;}
.flex-direction-nav .flex-next:before {font-family: 'FontAwesome'; content: "\f054"; }
.flex-direction-nav .flex-prev {left: -40px;}
.flex-direction-nav .flex-prev:before {font-family: 'FontAwesome'; content: "\f053"; }
.flexslider:hover .flex-next, .flexslider-photo:hover .flex-next {opacity: 1; right: 30px;}
.flexslider:hover .flex-prev, .flexslider-photo:hover .flex-prev {opacity: 1; left: 30px;}
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover, .flexslider-photo:hover .flex-next:hover, .flexslider-photo:hover .flex-prev:hover {opacity: 1;text-decoration:none;color:#fff;}
.flex-direction-nav .disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; left:0;bottom:20px; text-align: center;margin:0;padding:0;}
.flex-control-nav li {margin: 0 6px;padding:0; display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {width: 15px; height: 15px;display: block; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px;border-radius:50%;}
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;padding:0 2px 0 3px;}
.flex-control-thumbs img {width: 100%; display: block;padding:5px; cursor: pointer; border:1px solid #e5e5e5;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {opacity: 0; left: 0;}
  .flex-direction-nav .flex-next {opacity: 0; right: 0;}
}